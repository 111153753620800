import { UploadOutlined } from '@ant-design/icons';
import { Button, message, PageHeader, Spin } from 'antd';
import Upload from 'antd/lib/upload/Upload';
import React, { useState } from 'react';
import { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  download_opening_stock_mutation,
  upload_opening_stock_mutation
} from '@/services/stock/mutations';
import AppContent from '@/components/Common/Content/Content';
import ReuseChannel from '../../channel/Reuse';
import { getUserData } from '@/utils/auth.utils';
import LocationsDB from '@/store/localstorage/LocationsDB';
import { get_location_details } from '@/services/locations/queries';
import { ILocations } from '@/services/locations/types';

const OpenStockDownloadAndUpload = () => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [isloading, setIsloading] = useState<boolean>(false);
  const [locationInfo, setLocationInfo] = useState<ILocations>();
  const uploadMutation = useMutation(upload_opening_stock_mutation);
  const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    // console.log(newFileList);
    setFileList(newFileList);
  };

  const { preferences } = getUserData();
  const preferenceLocationId = preferences?.preferences
    ? JSON.parse(preferences?.preferences)?.locationId
    : undefined;

  useQuery(['location', preferenceLocationId], async () => {
    if (preferenceLocationId) {
      let locationDetails = await LocationsDB.getLocation(preferenceLocationId);
      if (!locationDetails) {
        const location = await get_location_details(preferenceLocationId);
        await LocationsDB.addLocations([location]);
        locationDetails = await LocationsDB.getLocation(preferenceLocationId);
      }
      setLocationInfo(locationDetails);
    } else {
      message.error('Please set a preference location first!');
    }
  });

  const beforeUpload = (values: any) => {
    return false;
  };
  const uploadProps: UploadProps = {
    name: 'media',
    multiple: false,
    maxCount: 1,
    fileList: fileList,
    accept: '.xlsx',
    onChange: onChange,
    beforeUpload: beforeUpload,
    showUploadList: {
      showPreviewIcon: false,
      showRemoveIcon: true
    }
  };

  const handleUpload = async () => {
    setIsloading(true);
    try {
      const formData = new FormData();
      console.log(fileList[0]);
      formData.append('file', fileList[0].originFileObj as RcFile);
      console.log('formData', formData);
      const values = {
        file: formData
      };
      uploadMutation.mutateAsync(values, {
        onSuccess: async ({ data }: { data: any }) => {
          message.success('Uploaded successfully');
          setIsloading(false);
          // console.log(data);
        },
        onError: (error: any) => {
          // console.log(error);
          setIsloading(false);
          message.error(error.message);
        }
      });
    } catch (error: any) {
      message.error('unable to upload');
      setIsloading(false);
    }
  };

  const breadcrumbItems = [
    {
      label: 'Upload or Download Opening Stock',
      link: '/products/price-group'
    }
  ];

  const handleDownload = async () => {
    setIsloading(true);
    try {
      if (preferenceLocationId) {
        const response = await download_opening_stock_mutation(preferenceLocationId);
        window.open(response.data, '_blank');
      } else {
        message.error('Please set a preference location first!');
      }
    } catch (error: any) {
      message.error('unable to fetch');
    }
    setIsloading(false);
  };

  return (
    <div>
      <Spin spinning={isloading}>
        <AppContent breadcrumbItems={breadcrumbItems}>
          <PageHeader
            title={`Price Information by Location: ${locationInfo?.name} `}
            style={{
              padding: '8px 0px 0px 0px'
            }}
          />
          <PageHeader
            subTitle={'*Preference Location is selected*'}
            style={{
              padding: '0px 0px 8px 0px'
            }}
          />
          <div className="flex gap-5 mt-10 mb-5 flex-wrap">
            <Upload {...uploadProps}>
              <Button
                style={{ width: 150 }}
                icon={<UploadOutlined />}
                disabled={fileList.length > 0}>
                Upload File
              </Button>
            </Upload>
            <Button
              type="primary"
              onClick={handleUpload}
              disabled={fileList.length === 0}
              loading={isloading}
              style={{ width: 150 }}>
              {isloading ? 'loading' : 'Start Upload'}
            </Button>
            <Button
              onClick={handleDownload}
              disabled={isloading}
              type={'primary'}
              style={{ borderRadius: '9px' }}>
              Download
            </Button>
          </div>
          <ReuseChannel slug={`opening_stock_upload_${null}`} />
        </AppContent>
      </Spin>
    </div>
  );
};

export default OpenStockDownloadAndUpload;
