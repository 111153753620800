import { useState } from 'react';

import WidgetCustomizeWrapper from './wrapper/WidgetCustomizeWrapper';
import {
  IWidgetConfiguration,
  IGroupedData,
  IWidgetGetConfig
} from '@/services/dashboard/v3/types';
import WidgetMainWrapper from './wrapper/WidgetMainWrapper';
import { message, Modal } from 'antd';
import getPascalCase from '@/utils/getPascalCase';
import useWindowDimensions from '@/hooks/useWindowDimension';
import { reportCompareField } from '../constant.dashboard';
import { getCompareData, getGlobalSingleData } from './queries';

interface Props {
  type: 'main' | 'customize';
  widget: IWidgetConfiguration;
  defaultAPIConfig: { locationId: number; page: number; size: number };
  reports: { id: number; name: string }[];
}

function SingleReportWidget({ widget, defaultAPIConfig, type, reports }: Props) {
  const [data, setData] = useState<IGroupedData | undefined>(undefined);
  const reportWidget = widget.widgets[0];
  const { width } = useWindowDimensions();

  const [isCompare, setIsCompare] = useState(false);
  const [compareFields, setCompareFields] = useState<{ fields: string[]; plotAgainst: string }>({
    fields: [],
    plotAgainst: ''
  });

  const [baseMemoProp, setBaseMemoProp] = useState('');
  const [memoReport, setMemoReport] = useState<string | undefined>();

  const [isFullScreen, setIsFullScreen] = useState(false);

  function handleFullScreen() {
    setIsFullScreen((prev) => !prev);
  }

  async function getConfig(props: IWidgetGetConfig) {
    const config = JSON.stringify(reportWidget.reportId);
    setBaseMemoProp(JSON.stringify(props));
    if (memoReport !== config) {
      setMemoReport(config);
      setData(undefined);
    }

    setIsCompare(props.type === 'compare');

    if (props.type === 'compare') {
      const currentFilterField = Object.values(reportCompareField).find(
        (field) => field.filter === widget.comparable?.formField
      );

      if (!currentFilterField) {
        return message.error('Failed to generate plotAgainst field');
      }

      const data = await getCompareData(props, {
        currentFilterField,
        reportWidget,
        defaultAPIConfig
      });

      setData(data.data);
      setCompareFields({ fields: data.fields, plotAgainst: data.plotAgainst });
      return;
    }

    const data = await getGlobalSingleData(props, {
      reportWidget,
      defaultAPIConfig,
      isPlotAgainstDate: widget.isPlotAgainstDate
    });

    setData(data);
  }

  async function modalGetConfig(props: IWidgetGetConfig) {
    const config = JSON.stringify(props);
    if (baseMemoProp === config) return;

    await getConfig(props);
  }

  const modalWidth = width > 760 ? '80%' : '100%';

  return (
    <>
      {type === 'main' && (
        <>
          <Modal
            title={widget.title}
            data-no-dnd="true"
            width={modalWidth}
            footer={null}
            bodyStyle={{ paddingTop: 0 }}
            visible={isFullScreen}
            onCancel={() => setIsFullScreen(false)}>
            <WidgetMainWrapper
              key={JSON.stringify({
                repordId: reportWidget.reportId,
                comparable: widget.comparable ? widget.comparable.formField : false
              })}
              data={data}
              widget={{ ...widget, showFilter: false, enlargeChart: false }}
              reports={reports}
              getConfig={modalGetConfig}
              xAxisLabel={
                isCompare ? compareFields.plotAgainst : getPascalCase(reportWidget.plotAgainst)
              }
              fields={isCompare ? compareFields.fields : reportWidget.field}
            />
          </Modal>

          <WidgetMainWrapper
            key={JSON.stringify({
              repordId: reportWidget.reportId,
              comparable: widget.comparable ? widget.comparable.formField : false
            })}
            data={data}
            widget={widget}
            reports={reports}
            getConfig={getConfig}
            fields={isCompare ? compareFields.fields : reportWidget.field}
            xAxisLabel={
              isCompare ? compareFields.plotAgainst : getPascalCase(reportWidget.plotAgainst)
            }
            handleFullScreen={handleFullScreen}
          />
        </>
      )}

      {type === 'customize' && (
        <>
          <Modal
            title={widget.title}
            data-no-dnd="true"
            width={modalWidth}
            footer={null}
            visible={isFullScreen}
            bodyStyle={{ paddingTop: 0 }}
            onCancel={() => setIsFullScreen(false)}>
            <WidgetCustomizeWrapper
              key={JSON.stringify({
                repordId: reportWidget.reportId,
                comparable: widget.comparable ? widget.comparable.formField : false
              })}
              data={data}
              widget={{ ...widget, showFilter: false, enlargeChart: false }}
              reports={reports}
              getConfig={modalGetConfig}
              xAxisLabel={
                isCompare ? compareFields.plotAgainst : getPascalCase(reportWidget.plotAgainst)
              }
              fields={isCompare ? compareFields.fields : reportWidget.field}
            />
          </Modal>

          <WidgetCustomizeWrapper
            key={JSON.stringify({
              repordId: reportWidget.reportId,
              comparable: widget.comparable ? widget.comparable.formField : false
            })}
            data={data}
            widget={widget}
            reports={reports}
            getConfig={getConfig}
            fields={isCompare ? compareFields.fields : reportWidget.field}
            xAxisLabel={
              isCompare ? compareFields.plotAgainst : getPascalCase(reportWidget.plotAgainst)
            }
            handleFullScreen={handleFullScreen}
          />
        </>
      )}
    </>
  );
}

export default SingleReportWidget;
