import { useEffect, useState } from 'react';
import { DatePickerProps, RangePickerProps } from 'antd/lib/date-picker';
import { DatePicker, Form, Input, InputNumber, Select, TableProps } from 'antd';

import { CustomDatePresets } from '../utils/datePresets';
import moment from 'moment';
import type { ColumnsType } from 'antd/es/table';
import { CustomModal } from '@/components/Common/CustomModal';
import ReusableSellView from '../../../sell/view/ReusableView';
import CustomReport from './report';
import ReusablePurchaseView from '../../../purchases/view/ReusableView';
const { Option } = Select;
import { SorterResult } from 'antd/lib/table/interface';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import { find_date_preference, find_to_use_NPR } from '@/store/localstorage/preferences';
import { NepaliDatePicker } from 'nepali-datepicker-reactjs';
import { convert_nepali_to_english_date } from '@/utils/nepaliDateConverter';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';

const FullDayReport = () => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [id, setId] = useState(0);
  const [id2, setId2] = useState(0);
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const [nepaliType] = useState(find_date_preference());
  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };
  useEffect(() => {
    form.setFieldValue('constraints', {
      page: 1,
      size: 100,
      dateCustom: [...CustomDatePresets.Today],
      locationId: '',
      from: CustomDatePresets.Today[0].format('YYYY-MM-DD HH:mm'),
      to: CustomDatePresets.Today[1].format('YYYY-MM-DD HH:mm')
    });
  }, []);

  const breadcrumbItems = [
    {
      label: 'Reports',
      link: '/reports'
    },
    {
      label: 'Full Day Report',
      link: '/reports/full-day-report'
    }
  ];
  const onChange: DatePickerProps['onChange'] = (dates, dateStrings) => {
    if (dates) {
      form.setFieldValue(['constraints', 'from'], dates.format('YYYY-MM-DD HH:mm'));
      form.setFieldValue(
        ['constraints', 'to'],
        moment(dateStrings).add(1, 'days').format('YYYY-MM-DD HH:mm')
      );
    }
  };
  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return current && current > moment().endOf('day');
  };
  const columnsPurchase: ColumnsType<any> = [
    {
      title: 'S.N',
      key: 'SN',
      width: 60,
      // sorter: (a, b) => a.id - b.id,
      // sortOrder: sortedInfo.columnKey === 'id' ? sortedInfo.order : null,
      render: (text, record, index) => {
        return <div color="black">{index + 1}</div>;
      }
    },
    {
      title: 'ID',
      key: 'id',
      render: (record) => {
        return <div color="black">{record.id}</div>;
      }
    },
    {
      title: 'Customer Name',
      key: 'userName',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.userName.localeCompare(b.userName),
      sortOrder: sortedInfo.columnKey === 'userName' ? sortedInfo.order : null,
      render: (record) => {
        return (
          // <Link to={`/users/${record.address.userId}`} color="black">
          <div>{record.userName}</div>
          // </Link>
        );
      }
    },
    {
      title: 'Invoice No.',
      key: 'reference_number',
      className: 'invoice',
      sorter: (a, b) => a.reference_number.localeCompare(b.reference_number),
      sortOrder: sortedInfo.columnKey === 'reference_number' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <a
            color="black"
            onClick={() => {
              setIsModalOpen2(true);
              setId2(record.id);
            }}>
            {record.reference_number}
          </a>
        );
      }
    },
    {
      title: 'Total',
      key: 'total_amount',
      className: 'highlight',
      sorter: (a, b) => a.total_amount - b.total_amount,
      sortOrder: sortedInfo.columnKey === 'total_amount' ? sortedInfo.order : null,
      render: (record) => {
        return <div className="text-right mr-2">{nepaliNumberFormatter(record.total_amount)}</div>;
      }
    },
    {
      title: 'Return Total',
      key: 'amount_returned',
      sorter: (a, b) => a.amount_returned - b.amount_returned,
      sortOrder: sortedInfo.columnKey === 'amount_returned' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <div className="text-right mr-2">{nepaliNumberFormatter(record.amount_returned)}</div>
        );
      }
    },
    {
      title: 'Total after Return',
      key: 'totalafterReturn',
      sorter: (a, b) => {
        const atotalRem = a.total_amount - a.amount_returned;
        const btotalRem = b.total_amount - b.amount_returned;
        return atotalRem - btotalRem;
      },
      sortOrder: sortedInfo.columnKey === 'totalafterReturn' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <div className="text-right mr-2">
            {nepaliNumberFormatter(record.total_amount - record.amount_returned)}
          </div>
        );
      }
    },
    {
      title: 'Amount Paid',
      key: 'amount_paid',
      sorter: (a, b) => a.amount_paid - b.amount_paid,
      sortOrder: sortedInfo.columnKey === 'amount_paid' ? sortedInfo.order : null,
      render: (record) => {
        return <div className="text-right mr-2">{nepaliNumberFormatter(record.amount_paid)}</div>;
      }
    }
  ];
  const columnsSell: ColumnsType<{ name: string }> = [
    {
      title: 'ID',
      key: 'id',
      render: (record) => {
        return <div color="black">{record.id}</div>;
      },
      width: 80
    },
    {
      title: 'Customer Name',
      key: 'userName',

      render: (record) => {
        return (
          // <Link to={`/users/${record.address.userId}`} color="black">
          <div>{record.userName}</div>
          // </Link>
        );
      },
      width: 100
    },
    {
      title: 'Invoice No.',
      key: 'reference_number',
      render: (record) => {
        return (
          <a
            color="black"
            onClick={() => {
              setIsModalOpen(true);
              setId(record.id);
            }}>
            {record.reference_number}
          </a>
        );
      },
      width: 100
    },
    {
      title: 'Total',
      key: 'total_amount',
      render: (record) => {
        return <div className="text-right mr-2">{nepaliNumberFormatter(record.total_amount)}</div>;
      },
      width: 100
    },
    {
      title: 'Return Total',
      key: 'amount_returned',
      render: (record) => {
        return (
          <div className="text-right mr-2">{nepaliNumberFormatter(record.amount_returned)}</div>
        );
      },
      width: 100
    },
    {
      title: 'Total after Return',
      key: 'totalafterReturn',
      render: (record) => {
        return (
          <div className="text-right mr-2">
            {nepaliNumberFormatter(record.total_amount - record.amount_returned)}
          </div>
        );
      },
      width: 100
    },
    {
      title: 'Amount Paid',
      key: 'amount_paid',
      render: (record) => {
        return <div className="text-right mr-2">{nepaliNumberFormatter(record.amount_paid)}</div>;
      },
      width: 100
    }
  ];

  const onChangeSingleNepali = (val: string) => {
    // console.log('vall', val);
    form.setFieldValue(['date'], convert_nepali_to_english_date(val));
    const englishDate = convert_nepali_to_english_date(val);
    form.setFieldValue(['constraints', 'from'], convert_nepali_to_english_date(val));
    form.setFieldValue(
      ['constraints', 'to'],
      moment(englishDate).add(1, 'days').format('YYYY-MM-DD')
    );
  };

  return (
    <CustomReport
      breadcrumbItems={breadcrumbItems}
      state={{ name: 'Full Day Report' }}
      form={form}
      columnsData={[columnsSell, columnsPurchase]}
      sortHandle={handleChange}>
      <CustomModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} title={'Sell View'}>
        <ReusableSellView id={id} />
      </CustomModal>
      <CustomModal
        isModalOpen={isModalOpen2}
        setIsModalOpen={setIsModalOpen2}
        title={'Purchase View'}>
        <ReusablePurchaseView id={id2} />
      </CustomModal>
      <Form.Item label={'page'} name={['constraints', 'page']} hidden={true}>
        <InputNumber />
      </Form.Item>
      <Form.Item label={'size'} name={['constraints', 'size']} hidden={true}>
        <InputNumber />
      </Form.Item>
      <Form.Item
        name={['constraints', 'type']}
        label="Type"
        rules={[
          {
            required: true,
            message: 'Please select type!'
          }
        ]}>
        <Select placeholder="Select a type!" allowClear>
          <Option value={'sell'} key={'sell'}>
            Sell
          </Option>
          <Option value={'purchase'} key={'purchase'}>
            Purchase
          </Option>
        </Select>
      </Form.Item>
      <Form.Item
        label={'Date'}
        valuePropName={'date'}
        name={['constraints', 'dateCustom']}
        rules={[
          {
            required: true,
            message: 'Please add date!'
          }
        ]}>
        {nepaliType ? (
          <NepaliDatePicker
            onChange={onChangeSingleNepali}
            // value={'2079-09-29'}
            options={{ calenderLocale: 'ne', valueLocale: 'en' }}
          />
        ) : (
          <DatePicker
            showTime
            disabledDate={disabledDate}
            defaultValue={CustomDatePresets.Today[0]}
            format={'YYYY-MM-DD HH:mm'}
            onChange={onChange}
            allowClear={false}
          />
        )}
      </Form.Item>
      <Form.Item label={'Date'} name={['constraints', 'from']} hidden>
        <Input />
      </Form.Item>
      <Form.Item label={'Date'} name={['constraints', 'to']} hidden>
        <Input />
      </Form.Item>
      <LocationSearchV2 showAll hasParentFormItem={false} name={['constraints', 'locationId']} />
    </CustomReport>
  );
};

export default FullDayReport;
