import React, { useContext, useEffect, useState } from 'react';
import { Avatar, Button, Dropdown, Input, Layout, Menu, message, Progress, Tooltip } from 'antd';
import { MenuOutlined, UserOutlined, WechatOutlined, SearchOutlined } from '@ant-design/icons';

import { AuthContext } from '../contexts/auth.context';
import { useMutation } from '@tanstack/react-query';
const { Header } = Layout;
import { useNavigate } from 'react-router-dom';
import Notifications from './notification';
import useWindowDimensions from '../hooks/useWindowDimension';
import { find_check_in_preference } from '../store/localstorage/preferences';
import { getUserData } from '../utils/auth.utils';
import { update_preference_user_mutation } from '../services/users/mutations';
import { setLocalStorage } from '../utils/storage.utils';
import { USER_INFO } from '../constants/config';
import { check_in_attendance, check_out_attendance } from '../services/attendance/mutations';
import { CustomModal } from '../components/Common/CustomModal';
import { LocationSearch } from '../components/Common/LocationSearch/LocationSearch';
import { get_global_settings } from '../services/settings/queries';
import { SearchForMenuModal } from '../components/Common/CustomModal/SearchForMenuModal';
import MenuDB from '../store/localstorage/MenuDB';
import { IMenuList } from '../services/settings/types';

import { useGlobalContext } from '@/contexts/global.context';
import {
  syncUnitsList,
  syncUserList,
  syncAgentsList,
  syncFinancialYears,
  syncLocationsList,
  syncMenuItems,
  syncProductsList,
  syncRoutesList,
  syncVehicleList,
  syncGlobalSettings
} from './sync.services';
import NotificationV2 from './notification/v2';

interface IHeaderWrapperProps {
  // eslint-disable-next-line no-unused-vars
  collapsed: boolean;
  showHamburger: boolean;
  setshowDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  refetchMenu: () => void;
  isSecondaryMenu?: boolean;
  showSubMenuHamburger?: boolean;
}

const NotificationsComponent = React.memo(() => {
  return <NotificationV2 />;
});

NotificationsComponent.displayName = 'NotificationComponent';

const HeaderWrapper = ({
  showHamburger,
  setshowDrawer,
  collapsed,
  refetchMenu,
  isSecondaryMenu = false,
  showSubMenuHamburger
}: IHeaderWrapperProps) => {
  const { profileData, signOut } = useContext(AuthContext);
  const { height, width } = useWindowDimensions();
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const [isCheck, setIsCheck] = useState(false);
  const [location, setLocation] = useState<number>();
  const [locationString, setLocationString] = useState('');
  const [searchMenuItems, setSearchMenuItems] = useState<IMenuList[]>([]);
  const userData = getUserData();
  const prefUpdate: any = JSON.parse(
    userData?.preferences ? userData.preferences.preferences : '{}'
  );

  const { setShowSecondaryDrawer, showInfo } = useGlobalContext();

  const [percentcomplete, setPercentComplete] = useState(0);
  const [isModalOpentwo, setIsModalOpentwo] = useState(false);
  const [isModalOpenSearchMenu, setIsModalOpenSearchMenu] = useState(false);
  const checkInAttendanceMutation = useMutation(check_in_attendance, {
    onSuccess: () => {
      message.success('Checked in!');
      setIsCheck(!isCheck);
      prefUpdate.isCheckIn = true;
      updatePreferencesMutation.mutateAsync({
        id: userData.id,
        value: JSON.stringify(prefUpdate)
      });
    },
    onError: (error: any) => {
      message.error(error.message);
      setIsModalOpen(false);
      setIsCheck(true);
    }
  });

  const checkOutAttendanceMutation = useMutation(check_out_attendance, {
    onSuccess: () => {
      message.success('Checked out!');
      setIsCheck(!isCheck);
      prefUpdate.isCheckIn = false;
      updatePreferencesMutation.mutateAsync({
        id: userData.id,
        value: JSON.stringify(prefUpdate)
      });
    },
    onError: (error: any) => {
      message.error(error.message);
    }
  });

  const syncData = async () => {
    const syncGlobalSettingsFunc = () =>
      syncGlobalSettings().then((data) => {
        if (!data) return;

        let loggedInUser = getUserData();
        loggedInUser = { ...loggedInUser, global: data };
        setLocalStorage(USER_INFO, loggedInUser);
      });

    setIsModalOpentwo(true);
    if (percentcomplete == 0) {
      const syncOperations = [
        { name: 'Users', func: syncUserList, increment: 10 },
        { name: 'Units', func: syncUnitsList, increment: 10 },
        { name: 'Products', func: syncProductsList, increment: 10 },
        { name: 'Locations', func: syncLocationsList, increment: 10 },
        { name: 'Routes', func: syncRoutesList, increment: 10 },
        { name: 'Financial Years', func: syncFinancialYears, increment: 10 },
        { name: 'Agents', func: syncAgentsList, increment: 10 },
        { name: 'Vehicle', func: syncVehicleList, increment: 10 },
        { name: 'Menu Items', func: syncMenuItems, increment: 10 },
        { name: 'Global Settings', func: syncGlobalSettingsFunc, increment: 10 }
      ];

      const promises = syncOperations.map(({ func, increment, name }) =>
        func()
          .then(() => ({ name, status: 'fulfilled' }))
          .catch(() => ({ name, status: 'rejected' }))
          .finally(() => setPercentComplete((prev) => prev + increment))
      );

      const results = await Promise.all(promises);
      const failedOperations = results
        .filter((result) => result.status === 'rejected')
        .map((result) => result.name);

      if (failedOperations.length > 0) {
        const failedNames = failedOperations.join(', ');
        message.error(`Failed to sync: ${failedNames}!`);
      }

      refetchMenu();

      // const expenseCategoryList = await get_expense_category_list('skip=0&count=1000');
      // // console.log(expenseCategoryList);
      // if (expenseCategoryList) {
      //   ExpenseCategoryDB.add(expenseCategoryList);
      //   setPercentComplete((prev) => prev + 10);
      // }
    } else {
      return;
    }
  };

  const updatePreferencesMutation = useMutation(update_preference_user_mutation, {
    onSuccess: (data: any) => {
      // console.log(data);
      setLocalStorage(USER_INFO, {
        ...userData,
        preferences: {
          ...userData.preferences,
          preferences: data.data.preferences
        }
      });
      message.success('Store success!');
    }
  });
  const RightNavMenu = (
    <Menu
      items={[
        {
          key: 'sign-out',
          icon: <UserOutlined />,
          label: 'Sign Out',
          onClick: signOut
        },
        {
          key: 'sync',
          label: <div className="text-center">Sync</div>,
          onClick: syncData
        }
      ]}
    />
  );

  const handleCheckIn = async () => {
    setLoading(true);

    if (isCheck) {
      prefUpdate.isCheckIn = false;
      await checkOutAttendanceMutation.mutateAsync();
    } else {
      prefUpdate.isCheckIn = true;
      setIsModalOpen(true);
    }

    setLoading(false);
  };

  const modalCheckInFinal = async () => {
    if (!location) {
      message.error('Please select location');
      return;
    }
    await checkInAttendanceMutation.mutateAsync({
      location: locationString,
      locationId: location
    });
    setIsModalOpen(false);
  };
  useEffect(() => {
    const checkInPreference = find_check_in_preference();
    setIsCheck(checkInPreference);
  }, []);

  const handleCanceltwo = () => {
    setPercentComplete(0);
    setIsModalOpentwo(false);
  };

  const handleSearchMenu = async () => {
    const menuItems = await MenuDB.getAllMenuItem();
    setSearchMenuItems(menuItems);
    setIsModalOpenSearchMenu(true);
  };

  return (
    <Header
      className={`site-layout-background ${showHamburger ? '!px-4 md:!px-8' : ''}`}
      style={{
        position: 'fixed',
        margin: '0px',
        zIndex: 1000,
        width:
          showHamburger || showInfo
            ? '100vw'
            : collapsed
            ? `${width - 75 + 5}px`
            : `${width - 220 + 5}px`,
        transition: 'all 0.1s linear'
      }}>
      <CustomModal
        title={'Check In Details'}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        footer={false}>
        <div className="flex flex-col gap-5">
          <div className="flex flex-col gap-2">
            <label>Location</label>
            <Input
              value={locationString}
              onChange={(val) => {
                setLocationString(val.target.value);
              }}
            />
          </div>
          <LocationSearch
            notAll={true}
            preference={false}
            onSelect={(val) => {
              setLocation(val);
            }}
          />
          <div className="flex justify-end">
            <Button onClick={() => setIsModalOpen(false)}>Cancel</Button>
            <Button type="primary" onClick={modalCheckInFinal}>
              Check In
            </Button>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        title={'Syncing Data...!'}
        isModalOpen={isModalOpentwo}
        setIsModalOpen={setIsModalOpentwo}
        handleCancel={handleCanceltwo}
        width={'40%'}
        footer={false}>
        <div className="flex flex-col justify-center items-center">
          <Progress type="circle" percent={percentcomplete} />
          {percentcomplete == 100 ? (
            <div className="text-xl font-bold" style={{ color: '#58be23' }}>
              Completed!
            </div>
          ) : (
            <div className="text-xl font-bold" style={{ color: '#1890ff' }}>
              Loading...
            </div>
          )}
        </div>
      </CustomModal>
      <CustomModal
        title={''}
        isModalOpen={isModalOpenSearchMenu}
        setIsModalOpen={setIsModalOpenSearchMenu}
        style={{ overflowX: 'auto' }}
        width={'50%'}
        footer={false}>
        <SearchForMenuModal
          searchMenuItems={searchMenuItems}
          handleItemClick={() => setIsModalOpenSearchMenu(false)}
        />
      </CustomModal>
      <div
        className={`w-full h-full ${
          showHamburger || showInfo ? 'flex justify-between' : 'flex justify-end'
        } items-center`}>
        <div className="flex items-center gap-4 relative">
          {isSecondaryMenu && (showSubMenuHamburger || showInfo) && (
            <Button
              type="primary"
              className="rounded-sm"
              onClick={() => setShowSecondaryDrawer((prev) => !prev)}>
              <MenuOutlined className="scale-125" />
            </Button>
          )}

          {(showHamburger || showInfo) && (
            <div>
              <MenuOutlined className="scale-125" onClick={() => setshowDrawer((prev) => !prev)} />
            </div>
          )}
        </div>
        <div className="flex justify-center items-center gap-5">
          <div onClick={handleSearchMenu}>
            {width < 900 ? (
              <Tooltip title="search">
                <Button shape="circle" icon={<SearchOutlined />} />
              </Tooltip>
            ) : (
              <Input
                placeholder="Search for Menu..."
                prefix={<SearchOutlined className="site-form-item-icon" />}
                allowClear={true}
                readOnly={true}
              />
            )}
          </div>
          <Button
            loading={loading}
            onClick={handleCheckIn}
            type={isCheck ? 'default' : 'primary'}
            danger={isCheck}>
            {isCheck ? 'Check Out' : 'Check In'}
          </Button>
          <Tooltip title="Create Channel" color="green">
            <WechatOutlined
              onClick={() => navigate('/channel/new')}
              style={{ transform: 'scale(1.4)' }}
            />
          </Tooltip>
          <NotificationsComponent />
          <Dropdown overlay={RightNavMenu} trigger={['click']}>
            <div className="cursor-pointer">
              {!showHamburger && <span className="mr-5">{profileData?.name}</span>}
              <Avatar icon={<UserOutlined />} />
              {/* <div>Sync</div> */}
            </div>
          </Dropdown>
        </div>
      </div>
    </Header>
  );
};

export default HeaderWrapper;
