import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { useMutation } from '@tanstack/react-query';
import { Button, Collapse, Divider, Form, Input, message, PageHeader, Select } from 'antd';
import AppContent from '../../../components/Common/Content/Content';
import { create_source_mutation } from '../../../services/source/mutations';
const { Panel } = Collapse;

const CreateSource = () => {
  const [form] = Form.useForm();
  const breadcrumbItems = [
    {
      label: 'Source',
      link: '/source'
    },
    {
      label: 'Create',
      link: '/source/new'
    }
  ];
  const createSourceMutation = useMutation(create_source_mutation);

  const onFinish = async (values: any) => {
    console.log(values);
    values.scriptBefore = 'test';
    values.scriptAfter = 'test';
    await createSourceMutation.mutateAsync(values, {
      onSuccess: async ({ data }: { data: any }) => {
        message.success('Source created successfully');
        values.reasonId = data.id;
      },
      onError: (e: any) => {
        throw {
          name: 'SourceCreationError',
          message: `${e.response.data.message}`
        };
      }
    });
  };
  //Selections functions
  const SelectionInit = (name: string) => {
    return <Form.List name={[name]}>{SelectionItems}</Form.List>;
  };
  const SelectionItems = (fields: any, { add, remove }: any, { errors }: any) => (
    <>
      <PageHeader
        subTitle="Selections"
        style={{
          padding: '8px 0px 8px 10px'
        }}
      />

      {fields.map(({ key, name, ...restField2 }: any) => {
        return (
          <div className="grid grid-cols-4 gap-5 " key={key}>
            <Form.Item
              {...restField2}
              name={[name, 'name']}
              label="Name"
              rules={[
                {
                  required: true,
                  message: 'Please choose a name!'
                }
              ]}>
              {<Input placeholder="min(u.name)" />}
            </Form.Item>
            <Form.Item
              {...restField2}
              name={[name, 'alias']}
              label="Alias"
              rules={[
                {
                  required: true,
                  message: 'Please choose a Alias!'
                }
              ]}>
              {<Input placeholder="user_name" />}
            </Form.Item>
            {key > 0 && (
              <div className="flex items-center justify-start">
                <MinusCircleOutlined
                  className="dynamic-delete-button flex justify-center items-center"
                  onClick={() => remove(name)}
                />
              </div>
            )}
          </div>
        );
      })}
      <div className="flex justify-start ">
        <Button
          icon={<PlusCircleOutlined />}
          onClick={(val: any) => {
            add({
              name: '',
              alias: '',
              condition: ''
            });
          }}></Button>
      </div>
    </>
  );

  //Tables functions
  const TablesInit = (name: string) => {
    return <Form.List name={[name]}>{TablesItems}</Form.List>;
  };
  const TablesItems = (fields: any, { add, remove }: any, { errors }: any) => (
    <>
      <PageHeader
        subTitle="Tables"
        style={{
          padding: '8px 0px 8px 10px'
        }}
      />

      {fields.map(({ key, name, ...restField2 }: any) => {
        return (
          <div className="grid grid-cols-4 gap-5 " key={key}>
            <Form.Item
              {...restField2}
              name={[name, 'name']}
              label="Name"
              rules={[
                {
                  required: true,
                  message: 'Please choose a name!'
                }
              ]}>
              {<Input placeholder="user" />}
            </Form.Item>
            <Form.Item
              {...restField2}
              name={[name, 'alias']}
              label="Alias"
              rules={[
                {
                  required: true,
                  message: 'Please choose a Alias!'
                }
              ]}>
              {<Input placeholder="u" />}
            </Form.Item>
            <Form.Item name={['type']} label="Type">
              <Select defaultValue={'table'}>
                <Select.Option value={'table'}>Table</Select.Option>
                <Select.Option value={'datasource'}>Datasource</Select.Option>
              </Select>
            </Form.Item>
            {key > 0 ? (
              <Form.Item
                {...restField2}
                name={[name, 'condition']}
                label="Condition"
                rules={[
                  {
                    required: true,
                    message: 'Please choose a condition!'
                  }
                ]}>
                {<Input placeholder="p.author = u.id" />}
              </Form.Item>
            ) : null}
            {key > 0 && (
              <div className="flex items-center justify-start">
                <MinusCircleOutlined
                  className="dynamic-delete-button flex justify-center items-center"
                  onClick={() => remove(name)}
                />
              </div>
            )}
          </div>
        );
      })}
      <div className="flex justify-start ">
        <Button
          icon={<PlusCircleOutlined />}
          onClick={(val: any) => {
            add({
              name: '',
              alias: '',
              condition: '',
              type: 'table'
            });
          }}></Button>
      </div>
    </>
  );
  //Condition functions
  const ConditionInitial = (values?: any, restField?: any, initial = false) => {
    if (initial) return <Form.List name={[values]}>{Initial}</Form.List>;
    let checkLeaf = false;
    form.getFieldsValue(true, (val: any) => {
      if (val.name.length > 0) {
        if (val.name[val.name.length - 1] == 'conditions' && values != 'conditions') {
          const currentValue = form.getFieldValue([...val.name, values]);
          if (currentValue?.type == 'LEAF') {
            checkLeaf = true;
          }
        }
      }
      return false;
    });
    if (checkLeaf) {
      return (
        <Form.Item
          {...restField}
          name={[values, 'evaluation']}
          label="Evaluation"
          rules={[
            {
              required: true,
              message: 'Please type eval!'
            }
          ]}>
          {<Input />}
        </Form.Item>
      );
    }
    if (values == 'conditions') return <Form.List name={[values]}>{ConditionTest}</Form.List>;
    return (
      <>
        <Form.Item {...restField} name={[values, 'conditionType']} label="Condition Type">
          <Select defaultValue={'AND'}>
            <Select.Option value={'AND'}>AND</Select.Option>
            <Select.Option value={'OR'}>OR</Select.Option>
          </Select>
        </Form.Item>
        <Form.List name={[values, 'conditions']}>{ConditionTest}</Form.List>
      </>
    );
  };
  const ConditionTest = (fields: any, { add, remove }: any, { errors }: any) => (
    <>
      <Collapse accordion>
        {fields.map(({ key, name, ...restField2 }: any) => {
          return (
            //   <Panel header={`Condition ${name}`} key={key}>
            <Panel
              header={
                <div className="grid grid-cols-2">
                  {'Condition'}
                  <div className="flex items-center justify-end">
                    <MinusCircleOutlined
                      className="dynamic-delete-button flex justify-center items-center"
                      onClick={() => remove(name)}
                    />
                  </div>
                </div>
              }
              key={key}>
              <PageHeader
                subTitle="Conditions"
                style={{
                  padding: '8px 0px 8px 10px'
                }}
              />
              <div className="grid grid-cols-1 gap-5 mb-5" key={key}>
                <div className="grid grid-cols-2 gap-5 mb-5">
                  <Form.Item
                    {...restField2}
                    name={[name, 'name']}
                    label="Name"
                    rules={[
                      {
                        required: true,
                        message: 'Please choose a name!'
                      }
                    ]}>
                    {<Input />}
                  </Form.Item>
                  <Form.Item
                    {...restField2}
                    name={[name, 'type']}
                    label="Type"
                    rules={[
                      {
                        required: true,
                        message: 'Please choose a type!'
                      }
                    ]}>
                    {<Input disabled />}
                  </Form.Item>
                </div>
                {ConditionInitial(name, restField2)}
              </div>
            </Panel>
          );
        })}
      </Collapse>
      <div className="flex justify-end gap-2">
        <Button
          onClick={(val: any) => {
            add({
              name: 'test',
              type: 'CONDITION',
              conditionType: 'AND',
              evaluation: ' '
            });
          }}>
          add condition
        </Button>
        <Button
          onClick={() => {
            add({
              name: 'test',
              type: 'LEAF',
              evaluation: 'id==1',
              conditionType: 'none',
              conditions: []
            });
          }}>
          add Leaf
        </Button>
      </div>
    </>
  );

  const Initial = (fields: any, { add, remove }: any, { errors }: any) => (
    <>
      <Collapse accordion>
        {fields.map(({ key, name, ...restField2 }: any) => {
          return (
            //   <Panel header={`Condition ${name}`} key={key}>
            <Panel header={<div className="grid grid-cols-2">{'Condition'}</div>} key={key}>
              <PageHeader
                subTitle="Conditions"
                style={{
                  padding: '8px 0px 8px 10px'
                }}
              />
              <div className="grid grid-cols-1 gap-5 mb-5" key={key}>
                <div className="grid grid-cols-2 gap-5 mb-5">
                  <Form.Item
                    {...restField2}
                    name={[name, 'name']}
                    label="Name"
                    rules={[
                      {
                        required: true,
                        message: 'Please choose a name!'
                      }
                    ]}>
                    {<Input />}
                  </Form.Item>
                  <Form.Item
                    {...restField2}
                    name={[name, 'type']}
                    label="Type"
                    rules={[
                      {
                        required: true,
                        message: 'Please choose a type!'
                      }
                    ]}>
                    {<Input disabled />}
                  </Form.Item>
                </div>
                {ConditionInitial(name, restField2)}
              </div>
            </Panel>
          );
        })}
      </Collapse>
    </>
  );

  return (
    <AppContent breadcrumbItems={breadcrumbItems}>
      <PageHeader
        title="SQL Information"
        style={{
          padding: '8px 0px'
        }}
      />
      <Form
        form={form}
        onFinish={onFinish}
        initialValues={{
          tables: [
            {
              name: '',
              alias: '',
              type: 'data',
              condition: 'test'
            }
          ],
          selections: [
            {
              name: '',
              alias: ''
            }
          ],
          conditions: [
            {
              conditionType: 'AND',
              conditions: undefined,
              name: 'test',
              type: 'CONDITION',
              evaluation: ' '
            }
          ]
        }}
        layout="vertical"
        validateTrigger={'onChange'}
        // onValuesChange={(_, allFields) => {
        //   setProductDetails(allFields);
        // }}
        autoComplete="off">
        <div className="grid grid-cols-2 gap-5 mb-5">
          <Form.Item
            name={['name']}
            label="Name"
            rules={[
              {
                required: true,
                message: 'Please choose a name!'
              }
            ]}>
            {<Input placeholder="New Source" />}
          </Form.Item>

          <Form.Item
            name={['type']}
            label="Type"
            rules={[
              {
                required: true,
                message: 'Please choose a Type!'
              }
            ]}>
            {<Input />}
          </Form.Item>
        </div>
        <Form.Item
          name={['description']}
          label="Description"
          rules={[
            {
              required: true,
              message: 'Please choose a Description!'
            }
          ]}>
          {<Input />}
        </Form.Item>
        <Form.Item
          name={['sql']}
          label="SQL Query"
          rules={[
            {
              required: true,
              message: 'Please write a query!'
            }
          ]}>
          {<Input placeholder="Select * from users" />}
        </Form.Item>

        {TablesInit('tables')}
        <Divider />
        {SelectionInit('selections')}
        <Divider />

        {ConditionInitial('conditions', undefined, true)}
        <div className="flex justify-end mt-5">
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </div>
      </Form>
    </AppContent>
  );
};

export default CreateSource;
