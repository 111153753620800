import { Avatar, Breadcrumb, Button, Dropdown, Menu, Progress, message } from 'antd';
import { CustomModal } from '../../components/Common/CustomModal';
import { useContext, useState } from 'react';
import { UserOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import { USER_INFO } from '../../constants/config';
import { AuthContext } from '../../contexts/auth.context';
import { get_expense_category_list } from '../../services/expense/expense-category/queries';
import { get_location_list } from '../../services/locations/queries';
import { get_units_list, get_product_list } from '../../services/products/queries';
import { get_routes_list } from '../../services/routes/queries';
import { get_global_settings } from '../../services/settings/queries';
import { get_user_details, get_user_list } from '../../services/users/queries';
import ExpenseCategoryDB from '../../store/localstorage/ExpenseCategoryDB';
import LocationsDB from '../../store/localstorage/LocationsDB';
import ProductsDB from '../../store/localstorage/ProductsDB';
import RoutesDB from '../../store/localstorage/RoutesDB';
import UnitsDB from '../../store/localstorage/UnitsDB';
import UsersDB from '../../store/localstorage/UsersDB';
import { find_locationId_preference } from '../../store/localstorage/preferences';
import { getUserData } from '../../utils/auth.utils';
import { setLocalStorage } from '../../utils/storage.utils';
import Notifications from '../notification';
import { ILocations } from '../../services/locations/types';
import { checkAccess } from '../../routes/acl';
import { create_session_mutation } from '../../services/pos-session/mutations';
import PosClosingModal from '../../components/Common/CustomModal/PosClosingModal';
import { get_session_info } from '../../services/pos-session/queries';
import { IPosSessionResponse } from '../../services/pos-session/types';
import { IPurchaseSellSummation } from '../../pages/sqlsource/report/purchase-sell-summation-report';
import { get_report } from '../../services/report/queries';
import { convertLocalToUTCString } from '../../utils/convertToUTC';
import moment from 'moment';

interface IPosHeader {
  isSessionStart?: boolean;
  setIsSessionStart?: React.Dispatch<React.SetStateAction<boolean>>;
  isFullScreen: boolean;
  onFullScreenClick: () => void;
  onExitFullScreenClick: () => void;
}

const PosHeader: React.FC<IPosHeader> = ({
  isSessionStart,
  setIsSessionStart,
  isFullScreen,
  onFullScreenClick,
  onExitFullScreenClick
}) => {
  const { profileData, signOut } = useContext(AuthContext);
  const [percentcomplete, setPercentcomplete] = useState(0);
  const [isModalOpentwo, setIsModalOpentwo] = useState(false);
  const [locationInfo, setLocationInfo] = useState<ILocations>();
  const [isPosClosingModalOpen, setIsPosClosingModalOpen] = useState<boolean>(false);
  const [session, setSession] = useState<IPosSessionResponse>();
  const [data, setData] = useState<IPurchaseSellSummation[]>([]);

  const syncData = async () => {
    // console.log('Clicked!');
    setIsModalOpentwo(true);
    if (percentcomplete == 0) {
      const userList = await get_user_list(0, 1000);
      if (userList?.data?.results) {
        UsersDB.addUsers(userList.data.results);
        setPercentcomplete((prev) => prev + 20);
      }
      const allUnits = await get_units_list();
      // console.log('allUnits', allUnits);
      if (allUnits?.length >= 0) {
        UnitsDB.addUnits(allUnits);
        setPercentcomplete((prev) => prev + 20);
      }

      const allProducts = await get_product_list(0, 1000);
      // console.log('allProducts', allProducts);
      if (allProducts?.data?.results) {
        ProductsDB.addProducts(allProducts.data.results);
        setPercentcomplete((prev) => prev + 20);
      }

      const allRoute = await get_routes_list(0, 1000);
      if (allRoute?.data?.results) {
        RoutesDB.addRoutes(allRoute?.data?.results);
        setPercentcomplete((prev) => prev + 10);
      }
      // console.log('allroute', allRoute);
      const allLocation = await get_location_list();
      if (allLocation?.data?.results) {
        LocationsDB.addLocations(allLocation?.data?.results);
        setPercentcomplete((prev) => prev + 10);
      }

      const globalSetting = await get_global_settings();
      if (globalSetting?.data) {
        let loggedInUser = getUserData();
        loggedInUser = {
          ...loggedInUser,
          global: globalSetting.data
        };
        setLocalStorage(USER_INFO, loggedInUser);
        setPercentcomplete((prev) => prev + 10);
      }

      const expenseCategoryList = await get_expense_category_list('skip=0&count=1000');
      //console.log(expenseCategoryList);
      if (expenseCategoryList) {
        ExpenseCategoryDB.add(expenseCategoryList);
        setPercentcomplete((prev) => prev + 10);
      }
    } else {
      return;
    }
  };

  const handleCanceltwo = () => {
    setPercentcomplete(0);
    setIsModalOpentwo(false);
  };

  const RightNavMenu = (
    <Menu
      items={[
        {
          key: 'sign-out',
          icon: <UserOutlined />,
          label: 'Sign Out',
          onClick: signOut
        },
        {
          key: 'sync',
          label: <div className="text-center">Sync</div>,
          onClick: syncData
        }
      ]}
    />
  );

  const fetchLocation = async () => {
    const preferenceLocationId = find_locationId_preference();
    try {
      if (typeof preferenceLocationId === 'number') {
        const response = await LocationsDB.getLocation(preferenceLocationId);
        if (!response) {
          message.info('Cannot find any location with that name in cache, searching in server...');
          const allLocation = await get_location_list(0, 100);
          if (allLocation.data.results.length == 0) {
            message.error('Cannot find any location with that name in server!');
            setLocationInfo(undefined);
          } else {
            LocationsDB.addLocations(allLocation.data.results);
            setLocationInfo(
              allLocation.data.results.find((value) => value.id === preferenceLocationId)
            );
          }
        } else {
          setLocationInfo(response);
          return response;
        }
      }
    } catch (error) {
      message.error(error as string);
    }
  };

  const locationData = useQuery(['locationInfo'], async () => fetchLocation());

  const handleSessionStart = async () => {
    try {
      await startSessionMutation.mutateAsync();
    } catch (errors: any) {
      message.error(errors.message);
    }
  };

  const startSessionMutation = useMutation(create_session_mutation, {
    onSuccess: () => {
      message.success('Session started successfully!');
      if (setIsSessionStart) setIsSessionStart(true);
    },
    onError: (e: any) => {
      message.error(`${e.response.data.message}`);
    }
  });

  const handleSessionClose = async () => {
    const response = await get_session_info();
    if (response) {
      let userDetails = await UsersDB.getUser(response.userId);
      if (!userDetails) {
        const allUsers = await get_user_details(response.userId);
        await UsersDB.addUsers([allUsers.user]);
        userDetails = await UsersDB.getUser(response.userId);
      }

      if (typeof userDetails === 'object') response.userName = userDetails.name;
      setSession(response);

      // Fetch Pos Closing Report
      const values = {
        startDate: convertLocalToUTCString(response.startTime),
        endDate: convertLocalToUTCString(moment().local().add(1, 'minute')),
        sellStartDate: convertLocalToUTCString(response.startTime),
        sellEndDate: convertLocalToUTCString(moment().local().add(1, 'minute')),
        // paymentType: 'cash',
        userId: response.userId,
        page: 1,
        size: -1,
        locationId: locationInfo?.id
      };
      const report = await get_report(values, 49);
      if (report) setData(report.data);
    }

    setIsPosClosingModalOpen(true);
  };

  const handlePosClosingModalClose = () => {
    if (setIsSessionStart) setIsSessionStart(false);
    setIsPosClosingModalOpen(false);
  };

  return (
    <>
      <CustomModal
        title={'Syncing Data...!'}
        isModalOpen={isModalOpentwo}
        setIsModalOpen={setIsModalOpentwo}
        handleCancel={handleCanceltwo}
        width={'40%'}
        footer={false}>
        <div className="flex flex-col justify-center items-center">
          <Progress type="circle" percent={percentcomplete} />
          {percentcomplete == 100 ? (
            <div className="text-xl font-bold" style={{ color: '#58be23' }}>
              Completed!
            </div>
          ) : (
            <div className="text-xl font-bold" style={{ color: '#1890ff' }}>
              Loading...
            </div>
          )}
        </div>
      </CustomModal>
      <CustomModal
        title={'POS Closing'}
        isModalOpen={isPosClosingModalOpen}
        setIsModalOpen={setIsPosClosingModalOpen}
        footer={false}>
        <PosClosingModal
          sessionInfo={session}
          data={data}
          locationId={locationInfo?.id}
          handleModalClose={handlePosClosingModalClose}
        />
      </CustomModal>
      <div className="flex gap-x-4">
        <div className="grid grid-rows-2">
          <Breadcrumb style={{ margin: '10px 0px 0px 0px' }}>
            {(checkAccess('ADMIN') || checkAccess('B2B')) && (
              <Breadcrumb.Item>
                <Link to={'/sell'}>Sell</Link>
              </Breadcrumb.Item>
            )}
            <Breadcrumb.Item>Pos</Breadcrumb.Item>
          </Breadcrumb>
          <div className="text-lg font-extrabold">
            {locationData.isLoading ? 'Loading...' : locationInfo?.name}
          </div>
        </div>
        <div className="flex-1 flex gap-4 items-end">
          <Button
            type="primary"
            style={{ borderRadius: '6px' }}
            className="w-40"
            disabled={isSessionStart}
            onClick={async () => {
              await handleSessionStart();
            }}>
            Start Session
          </Button>
          <Button
            type="primary"
            danger
            style={{ borderRadius: '6px' }}
            className="w-40"
            disabled={!isSessionStart}
            onClick={() => {
              handleSessionClose();
            }}>
            End Session
          </Button>
          <Button
            type="primary"
            style={{ borderRadius: '6px' }}
            className="w-40"
            onClick={() => {
              if (!isFullScreen) {
                onFullScreenClick();
              } else {
                onExitFullScreenClick();
              }
            }}>
            {isFullScreen ? 'Exit FullScreen' : 'Enter FullScreen'}
          </Button>
        </div>
        <div className="flex justify-end items-center gap-5">
          <Notifications />
          {'|'}
          <Dropdown overlay={RightNavMenu} className="cursor-pointer">
            <div className="">
              <span className="mr-5">{profileData?.name}</span>
              <Avatar icon={<UserOutlined />} />
            </div>
          </Dropdown>
        </div>
      </div>
    </>
  );
};

export default PosHeader;
