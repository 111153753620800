import { DatePicker, Form, Input, InputNumber, PageHeader } from 'antd';
import { useEffect } from 'react';
import Report from '../..';
import { RangePickerProps } from 'antd/lib/date-picker';
import { CustomDatePresets } from '../../utils/datePresets';
const { RangePicker } = DatePicker;
import moment from 'moment';
import type { ColumnsType } from 'antd/es/table';

interface DataType {
  total_amount: string;
  total_discount: string;
  total_tax: string;
}

const TotalSaleReport = () => {
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldValue('constraints', {
      page: 1,
      size: 10
    });
  }, []);

  const breadcrumbItems = [
    {
      label: 'Reports',
      link: '/reports'
    },
    {
      label: 'Total Sale',
      link: '/reports/total-sale'
    }
  ];
  const onChange: RangePickerProps['onChange'] = (dates, dateStrings) => {
    if (dateStrings.length == 2) {
      form.setFieldValue(['constraints', 'startDate'], dateStrings[0]);
      form.setFieldValue(['constraints', 'endDate'], dateStrings[1]);
    }
  };

  const columns: ColumnsType<DataType> = [
    {
      title: 'Total Discount',
      dataIndex: 'total_discount',
      render: (text: string) => <a>{text}</a>
    },
    {
      title: 'Total Tax',
      dataIndex: 'total_tax',
      render: (text: string) => <a>{text}</a>
    },
    {
      title: 'Total Amount',
      dataIndex: 'total_amount',
      render: (text: string) => <a>{text}</a>
    }
  ];

  return (
    <Report
      breadcrumbItems={breadcrumbItems}
      state={{ id: 6, name: 'Total Sale Report' }}
      form={form}
      columnsData={columns}>
      <Form.Item label={'page'} name={['constraints', 'page']} hidden={true}>
        <InputNumber />
      </Form.Item>
      <Form.Item label={'size'} name={['constraints', 'size']} hidden={true}>
        <InputNumber />
      </Form.Item>
      <Form.Item
        label={'Pick a date'}
        name={['constraints', 'dateCustom']}
        rules={[
          {
            required: true,
            message: 'Please select date!'
          }
        ]}>
        <RangePicker
          ranges={CustomDatePresets}
          onChange={onChange}
          format={'YYYY-MM-DD'}
          className="w-full"
        />
      </Form.Item>
      <Form.Item label={'Start Date'} name={['constraints', 'startDate']} hidden>
        <Input />
      </Form.Item>
      <Form.Item label={'End Date'} name={['constraints', 'endDate']} hidden>
        <Input />
      </Form.Item>
    </Report>
  );
};

export default TotalSaleReport;
