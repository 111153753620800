import { useQuery } from '@tanstack/react-query';
import { DatePicker, Form, Input, InputNumber, PageHeader, Select } from 'antd';
import { useEffect, useState } from 'react';
import Report from '../..';
import { get_location_list } from '../../../../../services/locations/queries';
import { RangePickerProps } from 'antd/lib/date-picker';
import { CustomDatePresets } from '../../utils/datePresets';
import moment from 'moment';
import type { ColumnsType } from 'antd/es/table';
import { CustomModal } from '../../../../../components/Common/CustomModal';
import ReusableTransfersView from '../../../../transfers/view/ReusableView';
import { Link } from 'react-router-dom';
import { DEFAULT_DATE_FORMAT } from '@/constants';
const { RangePicker } = DatePicker;
const { Option } = Select;

interface DataType {
  created_at: string;
  date: string;
  discount: number;
  grade: string;
  id: number;
  identifier: string;
  identifier_id: number;
  misc: number;
  product_id: number;
  quantity: number;
  total_amount: number;
  unit_id: number;
  unit_price: number;
  updated_at: string;
  productName: string;
}

const TransferInLineReport = () => {
  const [form] = Form.useForm();
  const { data: locationsList } = useQuery(['locations'], async () => get_location_list());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [id, setId] = useState(0);
  useEffect(() => {
    form.setFieldValue('constraints', {
      page: 1,
      size: 10,
      identifier: 'TRANSFER_IN'
    });
  }, []);

  const breadcrumbItems = [
    {
      label: 'Reports',
      link: '/reports'
    },
    {
      label: 'Transfer In Line',
      link: '/reports/transfer-in-line'
    }
  ];
  const onChange: RangePickerProps['onChange'] = (dates, dateStrings) => {
    if (dateStrings.length == 2) {
      form.setFieldValue(['constraints', 'startDate'], dateStrings[0]);
      form.setFieldValue(['constraints', 'endDate'], dateStrings[1]);
    }
  };

  const columns: ColumnsType<DataType> = [
    {
      title: 'Product ID',
      key: 'id',
      render: (record) => <Link to={`/products/${record.product_id}`}>{record.product_id}</Link>
    },
    {
      title: 'Product',
      dataIndex: 'productName',
      render: (text: string) => <div>{text}</div>
    },
    {
      title: 'SKU',
      dataIndex: 'sku',
      render: (text: string) => <div>{text}</div>
    },
    {
      title: 'Transfer',
      key: 'identifier_id',
      render: (record) => {
        return (
          <a
            color="black"
            onClick={() => {
              setIsModalOpen(true);
              setId(record.identifier_id);
            }}>
            {record.identifier_id}
          </a>
        );
      }
    },
    // {
    //   title: 'Invoice No.',
    //   dataIndex: 'invoiceNo',
    //   render: (text: string) => <div>{text}</div>
    // },
    // {
    //   title: 'Customer Name',
    //   dataIndex: 'customerName',
    //   render: (text: string) => <div>{text}</div>
    // },
    {
      title: 'Date',
      dataIndex: 'date',
      render: (text: string) => <div> {moment(text).local().format(DEFAULT_DATE_FORMAT)}</div>
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      render: (text: string) => <div>{text}</div>
    },
    {
      title: 'Discount',
      dataIndex: 'discount',
      render: (text: string) => <div>{text}</div>
    },
    {
      title: 'Unit Price',
      dataIndex: 'unit_price',
      render: (text: string) => <div>{text}</div>
    },
    // {
    //   title: 'Tax',
    //   dataIndex: 'tax',
    //   render: (text: string) => <a>{text}</a>
    // },
    {
      title: 'SubTotal',
      dataIndex: 'total_amount',
      render: (text: string) => <a>{text}</a>
    }
  ];

  return (
    <Report
      breadcrumbItems={breadcrumbItems}
      state={{ id: 5, name: 'Transfer In Line Report' }}
      form={form}
      footerData={{ sell: true, purchase: true }}
      columnsData={columns}>
      <CustomModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        title={'Transfer View'}>
        <ReusableTransfersView id={id} />
      </CustomModal>
      <Form.Item label={'page'} name={['constraints', 'page']} hidden={true}>
        <InputNumber />
      </Form.Item>
      <Form.Item label={'size'} name={['constraints', 'size']} hidden={true}>
        <InputNumber />
      </Form.Item>
      <Form.Item label={'size'} name={['constraints', 'identifier']} hidden={true}>
        <InputNumber />
      </Form.Item>
      <Form.Item
        name={['constraints', 'locationId']}
        label="Location"
        rules={[
          {
            required: true,
            message: 'Please choose location!'
          }
        ]}>
        <Select placeholder="Select a location!" allowClear>
          {locationsList?.data.results.map((value: any) => {
            return (
              <Option value={value.id} key={value.id}>
                {value.name}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
      <Form.Item
        label={'Pick a date'}
        name={['constraints', 'dateCustom']}
        rules={[
          {
            required: true,
            message: 'Please select date!'
          }
        ]}>
        <RangePicker
          ranges={CustomDatePresets}
          onChange={onChange}
          format={'YYYY-MM-DD'}
          className="w-full"
        />
      </Form.Item>
      <Form.Item label={'Start Date'} name={['constraints', 'startDate']} hidden>
        <Input />
      </Form.Item>
      <Form.Item label={'End Date'} name={['constraints', 'endDate']} hidden>
        <Input />
      </Form.Item>
    </Report>
  );
};

export default TransferInLineReport;
