import { GoogleMap, LoadScript, Marker, Polyline } from '@react-google-maps/api';
import { useQuery } from '@tanstack/react-query';
import { Button, DatePicker, Slider, Typography } from 'antd';
import { DatePickerProps, RangePickerProps } from 'antd/lib/date-picker';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import AppContent from '../../../components/Common/Content/Content';
import { get_vehicles_history } from '../../../services/vehicle/queries';
import { IVehicleLiveData } from '../../../services/vehicle/types';
import { CustomDatePresets } from '../../sqlsource/report/utils/datePresets';

interface LatLng {
  lat: number;
  lng: number;
}
const { RangePicker } = DatePicker;

let intervalVal: number | undefined;
let markerVal: google.maps.Marker | undefined;
let polyLineVal: google.maps.Polyline | undefined;
let count = 0;
let countPoly = 0;
let rotation = 0;

const VehicleHistory = () => {
  const { id } = useParams();
  const [pause, setPause] = useState(true);
  const [reset, setReset] = useState(true);
  const [speed, setSpeed] = useState(5);

  const [path, setPathPosition] = useState<LatLng[]>([]);
  const [dateData, setDateData] = useState({
    startDate: CustomDatePresets['Today'][0].utc().format(),
    endDate: CustomDatePresets['Today'][1].utc().format()
  });
  const [allData, setAllData] = useState<any>();
  const [zoom, setZoom] = useState(13);

  const [center, setCenter] = useState({
    lat: 27.674804466363256,
    lng: 85.35849945181269
  });

  const onSearch = async () => {
    // const filter = `startDate=${dateData.startDate}&endDate=${dateData.endDate}`;
    const filter = `date=${dateData.startDate}`;
    const response = await get_vehicles_history(id, filter);

    const convertToPath: LatLng[] = response.data.record.map((val: any) => {
      return {
        lat: parseFloat(val.Lat),
        lng: parseFloat(val.Lng)
      };
    });
    setAllData(response.data);
    setPathPosition(convertToPath);
    setReset(true);
  };
  const onChange: DatePickerProps['onChange'] = (dates, dateStrings) => {
    if (dates) {
      setDateData({
        ...dateData,

        startDate: (dates as moment.Moment).utc().format()
      });
    }
  };
  useEffect(() => {
    onSearch();
  }, []);
  useEffect(() => {
    if (reset) {
      count = 0;
      setReset(false);
      rotation = 0;
      if (markerVal)
        markerVal.setLabel({
          text: 0 + ' km/h',
          color: 'red',
          fontSize: '12px',
          fontWeight: 'bold'
        });
    }
  }, [reset]);

  useEffect(() => {
    if (markerVal && !pause) animateMarker(markerVal);
    // if (polyLineVal && !pause) animatePolyLine(polyLineVal);
    return () => {
      clearInterval(intervalVal);
      intervalVal = undefined;
    };
  }, [pause]);

  function animateMarker(marker: google.maps.Marker) {
    intervalVal = window.setInterval(() => {
      if (pause) {
        return;
      }
      if (count == path.length) {
        count = 0;
        clearInterval(intervalVal);
        setPause(true);
      }
      rotateMarker(marker);
      marker.setPosition({
        lat: path[count].lat,
        lng: path[count].lng
      });
      count += 1;
    }, 1000 / speed);
  }

  const animatePolyLine = (polyline: google.maps.Polyline) => {
    intervalVal = window.setInterval(() => {
      if (pause) {
        return;
      }
      countPoly = (countPoly + 1) % 300;
      const icons = polyline.get('icons');
      icons[0].offset = countPoly / 2 + '%';
      polyline.set('icons', icons);
      // console.log(countPoly);
      // count += 1;
      if (count == path.length) {
        count = 0;
        clearInterval(intervalVal);
        setPause(true);
      }
    }, 1000 / speed);
  };

  const rotateMarker = (marker: google.maps.Marker) => {
    //compare two positions from path array and calculate the degree and rotate the marker and find the direction
    const heading = google.maps.geometry.spherical.computeHeading(path[count], path[count + 1]);
    const speed = calculateSpeed(
      path[count],
      path[count + 1],
      new Date(allData.record[count].Time),
      new Date(allData.record[count + 1].Time)
    );
    marker.setLabel({
      text: speed.toFixed(2) + ' km/h',
      color: 'black',
      fontSize: '18px',
      fontWeight: 'bold'
    });
    marker.setIcon({
      path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
      scale: 5,
      strokeColor: 'lime',
      rotation: heading
    });
    rotation = heading;
  };

  //calculate speed from two lat lng points and time difference in seconds
  const calculateSpeed = (d1: LatLng, d2: LatLng, time1: Date, time2: Date) => {
    const distance = google.maps.geometry.spherical.computeDistanceBetween(d1, d2);
    const timeDiff = (time2.getTime() - time1.getTime()) / 1000;
    const speed = distance / timeDiff;
    //convert speed to from m/s to km/h
    return speed * 3.6;
  };

  return (
    <AppContent breadcrumbItems={breadcrumbItems}>
      <div className="grid grid-cols-2 gap-5 mb-5">
        {/* <RangePicker
          defaultValue={CustomDatePresets['Today']}
          ranges={CustomDatePresets}
          onChange={onChange}
          format={'YYYY-MM-DD'}
          allowClear={false}
        /> */}
        <DatePicker
          defaultValue={CustomDatePresets['Today'][0]}
          onChange={onChange}
          format={'YYYY-MM-DD'}
          allowClear={false}
        />
        <Button onClick={() => onSearch()} disabled={!pause}>
          Search
        </Button>
      </div>
      <Typography.Text>Speed:</Typography.Text>
      <div className="grid grid-cols-3 gap-5 mb-5">
        <Slider
          value={speed}
          disabled={!pause}
          onChange={(val) => setSpeed(val)}
          min={1}
          max={100}
        />
        <Button onClick={() => setPause(!pause)}>{pause ? 'Play' : 'Pause'}</Button>
        <Button onClick={() => setReset(true)} disabled={!pause}>
          Reset
        </Button>
      </div>
      <div className="grid grid-cols-1 gap-5 mb-5">
        <Typography.Text>Vehicle IMEI: {allData?.IMEI}</Typography.Text>
      </div>
      <LoadScript googleMapsApiKey={'AIzaSyATJbgY-H04JRS-GO4GaKRHPPDHS0p2ENA'}>
        <GoogleMap
          center={center}
          zoom={zoom}
          options={{
            fullscreenControl: false,
            streetViewControl: false,
            mapTypeControl: false
          }}
          onClick={(val) => console.log(val)}
          mapContainerStyle={containerStyle}>
          {window.google && path.length > 0 && (
            <>
              <Marker
                position={{
                  lat: path[0].lat,
                  lng: path[0].lng
                }}
                label={'Start'}
                icon={{
                  // url: 'https://freshktm.github.io/ERP-UI/temp.png',
                  url: 'https://freshktm.github.io/ERP-UI/Flag.svg',
                  // anchor: new google.maps.Point(17, 46),
                  labelOrigin: new google.maps.Point(17, -10),
                  scaledSize: new google.maps.Size(37, 37)
                }}
              />
              <Marker
                ref={(marker) => {
                  if (marker) {
                    markerVal = marker.marker;
                  }
                }}
                position={{
                  lat: path[count].lat,
                  lng: path[count].lng
                }}
                label={{
                  text: 0 + ' km/h',
                  color: 'black',
                  fontSize: '18px',
                  fontWeight: 'bold'
                }}
                icon={{
                  // url: 'https://freshktm.github.io/ERP-UI/temp.png',
                  // url: `https://freshktm.github.io/ERP-UI/Moving.svg`,,
                  path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                  rotation: rotation,
                  scale: 5,
                  strokeColor: 'lime'
                }}
              />
              <Marker
                position={{
                  lat: path[path.length - 1].lat,
                  lng: path[path.length - 1].lng
                }}
                label={'End'}
                icon={{
                  // url: 'https://freshktm.github.io/ERP-UI/temp.png',
                  url: 'https://freshktm.github.io/ERP-UI/Flag.svg',
                  // anchor: new google.maps.Point(17, 46),
                  labelOrigin: new google.maps.Point(17, -10),
                  scaledSize: new google.maps.Size(37, 37)
                }}
              />
              {path.length > 1 && (
                <>
                  <Polyline
                    ref={(polyline) => {
                      if (polyline && polyline.state.polyline) {
                        polyLineVal = polyline.state.polyline;
                      }
                    }}
                    visible={true}
                    editable={false}
                    path={path}
                    options={{
                      strokeColor: '#145da0',
                      strokeOpacity: 0.5,
                      strokeWeight: 4,
                      clickable: false
                      // icons: [
                      //   {
                      //     icon: {
                      //       path: google.maps.SymbolPath.FORWARD_OPEN_ARROW,
                      //       strokeOpacity: 1,
                      //       strokeColor: '#000000',

                      //       scale: 3
                      //     }
                      //     // offset: '0',
                      //     // repeat: '200px'
                      //   }
                      // ]
                    }}
                  />
                  {/* <Polyline
                    visible={true}
                    editable={false}
                    path={path.slice(path.length / 4, path.length / 2 + 1)}
                    options={{
                      strokeColor: ' #FF0000',
                      strokeOpacity: 0.5,
                      strokeWeight: 4,
                      clickable: false,
                      icons: [
                        {
                          icon: {
                            path: google.maps.SymbolPath.FORWARD_OPEN_ARROW,
                            strokeOpacity: 1,
                            strokeColor: '#000000',

                            scale: 3
                          },
                          offset: '0',
                          repeat: '200px'
                        }
                      ]
                    }}
                  />
                  <Polyline
                    visible={true}
                    editable={false}
                    path={path.slice(path.length / 2, (path.length / 4) * 3 + 1)}
                    options={{
                      strokeColor: ' #00FF00',
                      strokeOpacity: 0.5,
                      strokeWeight: 4,
                      clickable: false,
                      icons: [
                        {
                          icon: {
                            path: google.maps.SymbolPath.FORWARD_OPEN_ARROW,
                            strokeOpacity: 1,
                            strokeColor: '#000000',

                            scale: 3
                          },
                          offset: '0',
                          repeat: '200px'
                        }
                      ]
                    }}
                  />
                  <Polyline
                    visible={true}
                    editable={false}
                    path={path.slice((path.length / 4) * 3, path.length)}
                    options={{
                      strokeColor: ' #FFFF00',
                      strokeOpacity: 0.5,
                      strokeWeight: 4,
                      clickable: false,
                      icons: [
                        {
                          icon: {
                            path: google.maps.SymbolPath.FORWARD_OPEN_ARROW,
                            strokeOpacity: 1,
                            strokeColor: '#000000',

                            scale: 3
                          },
                          offset: '0',
                          repeat: '200px'
                        }
                      ]
                    }}
                  />*/}
                </>
              )}
            </>
          )}
        </GoogleMap>
      </LoadScript>
    </AppContent>
  );
};
const containerStyle = {
  // width: '100%',
  height: '80vh'
  // marginBottom: '20px'
};
const breadcrumbItems = [
  {
    label: 'Vehicle',
    link: '/vehicle'
  },
  {
    label: 'History',
    link: '/vehicle/history'
  }
];

function animateCircle(line: google.maps.Polyline) {
  let count = 0;

  window.setInterval(() => {
    count = (count + 1) % 200;

    const icons = line.get('icons');

    icons[0].offset = count / 2 + '%';
    line.set('icons', icons);
  }, 20);
}
export default VehicleHistory;
