import { get_user_list } from '@/services/users/queries';
import { get_routes_list } from '../services/routes/queries';
import { get_agents_list_v2 } from '@/services/agents/queries';
import { get_vehicles_list } from '@/services/vehicle/queries';
import { get_location_list } from '../services/locations/queries';
import { get_financial_years } from '@/services/financial-year/queries';
import { get_global_settings, get_menu_items } from '../services/settings/queries';
import { get_product_list_v2, get_units_list } from '../services/products/queries';

import UsersDB from '../store/localstorage/UsersDB';
import UnitsDB from '../store/localstorage/UnitsDB';
import ProductsDB from '../store/localstorage/ProductsDB';
import LocationsDB from './../store/localstorage/LocationsDB';
import RoutesDB from './../store/localstorage/RoutesDB';
import { FinancialYearDB } from './../store/localstorage/FinancialYearDB';
import { AgentsDB } from './../store/localstorage/AgentsDB';
import { VehiclesDB } from './../store/localstorage/VehicleDB';
import MenuDB from './../store/localstorage/MenuDB';

export async function syncUserList() {
  const userList = await get_user_list(0, 1000);
  if (userList?.data?.results?.length > 0) {
    UsersDB.addUsers(userList.data.results);
  }
}

export const syncUnitsList = async () => {
  const unitsList = await get_units_list();
  if (unitsList?.length > 0) {
    UnitsDB.addUnits(unitsList);
  }
};

export const syncProductsList = async () => {
  const productsList = await get_product_list_v2('skip=0&count=-1');
  if (productsList?.data?.results?.length > 0) {
    ProductsDB.addProducts(productsList?.data?.results);
  }
};

export const syncLocationsList = async () => {
  const locationsList = await get_location_list();
  if (locationsList?.data?.results?.length > 0) {
    LocationsDB.addLocations(locationsList?.data?.results);
  }
};

export const syncRoutesList = async () => {
  const routesList = await get_routes_list(0, 1000);
  if (routesList?.data?.results?.length > 0) {
    RoutesDB.addRoutes(routesList?.data?.results);
  }
};

export const syncFinancialYears = async () => {
  const financialYears = await get_financial_years();
  if (financialYears?.length > 0) {
    FinancialYearDB.add(financialYears);
  }
};

export const syncAgentsList = async () => {
  const agentsList = await get_agents_list_v2(0, 1000);
  if (agentsList?.data?.results?.length > 0) {
    AgentsDB.addAgents(agentsList.data.results);
  }
};

export const syncVehicleList = async () => {
  const vehicleList = await get_vehicles_list();
  if (vehicleList?.data?.vehicles?.length > 0) {
    VehiclesDB.addVehicles(vehicleList.data.vehicles);
  }
};

export const syncMenuItems = async () => {
  const menuItems = await get_menu_items();
  if (menuItems?.data?.results?.length > 0) {
    MenuDB.removeAllMenuItems().then(() => MenuDB.addMenuItem(menuItems.data.results));
  }
};

export const syncGlobalSettings = async () => {
  const globalSetting = await get_global_settings();
  return globalSetting.data;
};
