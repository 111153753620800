import { useQuery, useMutation } from '@tanstack/react-query';
import { Form, Input, Button, Select, message, PageHeader, Divider } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import AppContent from '../../../components/Common/Content/Content';
import { ACLGroup, IACLUserGroupDetails, IEditACLUserGroup } from '../../../services/acl/types';
import { update_acl_user_group_mutation } from '../../../services/acl/mutations';
import { get_acl_user_group_details, get_all_acl_groups } from '../../../services/acl/queries';
import { MinusCircleOutlined } from '@ant-design/icons';
import ReuseChannel from '../../channel/Reuse';
import { AxiosResponse, AxiosError } from 'axios';
import { IServerError } from '../../../services/response/types';

const { Option } = Select;

const UserACL = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm<IEditACLUserGroup>();
  const { id } = useParams();
  const [isloading, setIsloading] = useState<boolean>(true);
  const { data: allData } = useQuery(['acl-user-list'], async () => {
    try {
      const response = await get_acl_user_group_details(parseInt(id as string));
      const data: {
        data: IACLUserGroupDetails[];
        allACLGroups?: ACLGroup[];
      } = { data: response };
      //console.log(response);
      const allACLGroups = await get_all_acl_groups();
      data.allACLGroups = allACLGroups.data;
      const groupIds = response.map((value: IACLUserGroupDetails) => value.groupId);
      form.setFieldsValue({ userId: parseInt(id as string), groupIds });
      setIsloading(false);
      return data;
    } catch (error) {
      message.error('Unable to load data');
    }
  });

  const breadcrumbItems = [
    {
      label: 'Users',
      link: '/users'
    },
    {
      label: 'Assign Groups'
    }
  ];

  const editUserACLMutation = useMutation<
    AxiosResponse<ACLGroup>,
    AxiosError<IServerError>,
    IEditACLUserGroup
  >(update_acl_user_group_mutation);

  const onFinish = async (values: IEditACLUserGroup) => {
    if (values.groupIds.length == 0) {
      message.error('Please add at least 1 group!');
      return;
    }
    try {
      setIsloading(true);
      await editUserACLMutation.mutateAsync(values, {
        onSuccess: async (data) => {
          setIsloading(false);
          message.success('Success');
          navigate('/users');
        },
        onError: (error) => {
          setIsloading(false);
          message.error(`${error.response?.data.message}`);
        }
      });
    } catch (errors) {
      setIsloading(false);
    }
  };
  const displayName = (name: string) => {
    const value = form.getFieldValue(['groupIds', name]);
    const data = allData?.allACLGroups?.find((val) => val.id == value);
    return <div className="text-center">{data?.name ?? 'Fallback Value'}</div>;
  };

  useEffect(() => {
    form.setFieldsValue({ groupIds: [] });
  }, []);
  return (
    <div>
      <AppContent breadcrumbItems={breadcrumbItems}>
        <PageHeader
          title="ACL Informations"
          style={{
            padding: '8px 0px'
          }}
        />
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          disabled={isloading}
          validateTrigger={'onChange'}
          autoComplete="off">
          <Form.Item
            name="userId"
            label="userId"
            hidden
            rules={[
              {
                required: false,
                message: 'Please add a name!'
              }
            ]}>
            <Input />
          </Form.Item>
          <Divider />
          <Form.List name={['groupIds']}>
            {(fields2, { add: add2, remove: remove2 }, { errors: errors2 }) => (
              <>
                <div className={'grid mb-5'}>
                  <PageHeader
                    subTitle="Add Groups"
                    style={{
                      padding: '8px 0px 8px 10px'
                    }}
                  />
                  <Select
                    showSearch
                    placeholder={'select group'}
                    defaultActiveFirstOption={false}
                    filterOption={false}
                    onSelect={(val: number) => {
                      const selectedPerms = form.getFieldValue(['groupIds']);
                      const exists = selectedPerms.find((value: number) => value == val);
                      if (exists) {
                        message.error('Already added!');
                        return;
                      }
                      add2(val);
                    }}
                    notFoundContent={null}>
                    {allData?.allACLGroups?.map((value) => {
                      return (
                        <Option value={value.id} key={value.id}>
                          {value.name}
                        </Option>
                      );
                    })}
                  </Select>
                  {fields2.length > 0 && (
                    <PageHeader
                      title="All Permissions"
                      style={{
                        padding: '8px 0px 8px 10px'
                      }}
                    />
                  )}
                </div>
                <div className="grid grid-cols-4 gap-5 mb-5">
                  {fields2.map(({ key: key2, name: name2, ...restField2 }) => (
                    <div key={key2} className="card">
                      <div className="grid grid-cols-1 gap-4 " key={key2}>
                        <Form.Item {...restField2} name={[name2]} hidden>
                          <Input type={'text'} disabled />
                        </Form.Item>
                        <div>{displayName(name2.toString())}</div>
                        <div className="flex justify-center items-center">
                          {fields2.length > 0 ? (
                            <MinusCircleOutlined
                              className="dynamic-delete-button flex justify-center items-center"
                              onClick={() => remove2(name2)}
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </Form.List>

          <div className="flex justify-end mt-5">
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </div>
        </Form>
        <Divider />
        <ReuseChannel slug={`acl_user_${id}`} />
      </AppContent>
    </div>
  );
};

export default UserACL;
