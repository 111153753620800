import LocalStore from '.';
import { ILocations } from '../../services/locations/types';
export default class LocationsDB extends LocalStore {
  /*Locations Functions*/
  /*search for location by name, email, description, phone, state, city, zip, code with limit*/
  static async searchLocationByName(name: string, limit = 10) {
    if (!LocationsDB.db) await LocationsDB.init();
    return new Promise((resolve: (value: any[]) => void, reject) => {
      const transaction = LocationsDB.db.transaction('Locations', 'readonly');
      const objectStore = transaction.objectStore('Locations');
      const lowercaseString = name.toLowerCase();
      const cursorRequest = objectStore.index('LocationsSecondIndex').openCursor();
      const filteredLocations: any[] = [];

      cursorRequest.onsuccess = (event: any) => {
        const cursor = event.target.result;
        if (cursor) {
          if (
            cursor.value.lowercaseName.includes(lowercaseString) ||
            cursor.value.email?.toLowerCase().includes(lowercaseString) ||
            cursor.value.description?.toLowerCase().includes(lowercaseString) ||
            cursor.value.phone?.toLowerCase().includes(lowercaseString) ||
            cursor.value.state?.toLowerCase().includes(lowercaseString) ||
            cursor.value.city?.toLowerCase().includes(lowercaseString) ||
            cursor.value.zip?.toLowerCase().includes(lowercaseString) ||
            cursor.value.code?.toLowerCase().includes(lowercaseString)
          ) {
            filteredLocations.push(cursor.value);
          }
          if (filteredLocations.length < limit) cursor.continue();
        }
      };
      cursorRequest.onerror = (event: any) => {
        reject(event.target.error);
      };
      transaction.oncomplete = (event: any) => {
        resolve(filteredLocations);
      };
      transaction.onerror = (event: any) => {
        reject(event.target.error);
      };
    });
  }

  static async searchActiveLocationByName(name: string, limit = 10) {
    if (!LocationsDB.db) await LocationsDB.init();
    return new Promise((resolve: (value: any[]) => void, reject) => {
      const transaction = LocationsDB.db.transaction('Locations', 'readonly');
      const objectStore = transaction.objectStore('Locations');
      const lowercaseString = name.toLowerCase();
      const cursorRequest = objectStore.index('LocationsSecondIndex').openCursor();
      const filteredLocations: any[] = [];

      cursorRequest.onsuccess = (event: any) => {
        const cursor = event.target.result;

        if (cursor) {
          const validCondition =
            cursor.value.lowercaseName.includes(lowercaseString) ||
            cursor.value.email?.toLowerCase().includes(lowercaseString) ||
            cursor.value.description?.toLowerCase().includes(lowercaseString) ||
            cursor.value.phone?.toLowerCase().includes(lowercaseString) ||
            cursor.value.state?.toLowerCase().includes(lowercaseString) ||
            cursor.value.city?.toLowerCase().includes(lowercaseString) ||
            cursor.value.zip?.toLowerCase().includes(lowercaseString) ||
            cursor.value.code?.toLowerCase().includes(lowercaseString);

          if (validCondition && cursor.value.isActive) {
            filteredLocations.push(cursor.value);
          }
          if (filteredLocations.length < limit) cursor.continue();
        }
      };
      cursorRequest.onerror = (event: any) => {
        reject(event.target.error);
      };
      transaction.oncomplete = (event: any) => {
        resolve(filteredLocations);
      };
      transaction.onerror = (event: any) => {
        reject(event.target.error);
      };
    });
  }

  /*new data is added, existing data is updated*/
  static async addLocations(locationsData: any[]) {
    if (!LocationsDB.db) await LocationsDB.init();
    return new Promise((resolve, reject) => {
      const transaction = LocationsDB.db.transaction('Locations', 'readwrite');
      transaction.oncomplete = (event: any) => {
        // console.log('Event1-->', event);
        resolve('success');
      };
      transaction.onerror = (event: any) => {
        // console.log('event2-->', event);
        reject(event.target.error);
      };
      const objectStore = transaction.objectStore('Locations');
      locationsData.forEach(async (location) => {
        const checkKey = objectStore.count(location.id);
        checkKey.onsuccess = async (event: any) => {
          location.lowercaseName = location.name.toLowerCase();
          if (checkKey.result == 0) {
            const request = objectStore.add(location);
            request.onerror = (event: any) => {
              // console.log('Event3-->', event);
              reject(event.target.error);
            };
          } else {
            await LocationsDB.updateLocation(location);
          }
        };
      });
    });
  }
  static async getAllLocations() {
    if (!LocationsDB.db) await LocationsDB.init();
    return new Promise((resolve, reject) => {
      const transaction = LocationsDB.db.transaction('Locations', 'readonly');
      transaction.oncomplete = (event: any) => {
        resolve('success');
      };
      transaction.onerror = (event: any) => {
        reject(event.target.error);
      };
      const objectStore = transaction.objectStore('Locations');
      const request = objectStore.getAll();
      request.onsuccess = (event: any) => {
        resolve(event.target.result);
      };
      request.onerror = (event: any) => {
        reject(event.target.error);
      };
    });
  }
  static async removeAllLocations() {
    if (!LocationsDB.db) await LocationsDB.init();

    return new Promise((resolve, reject) => {
      const transaction = LocationsDB.db.transaction(['Locations'], 'readwrite');
      transaction.oncomplete = (event) => {
        resolve('success');
      };
      transaction.onerror = (event: any) => {
        reject(event.target.error);
      };
      const objectStore = transaction.objectStore('Locations');
      const objectStoreRequest = objectStore.clear();
      objectStoreRequest.onsuccess = (event: any) => {
        resolve(event.target.result);
      };
      objectStoreRequest.onerror = (event: any) => {
        reject(event.target.result);
      };
    });
  }
  static async getLocation(id: number | string): Promise<ILocations> {
    if (!LocationsDB.db) await LocationsDB.init();

    return new Promise((resolve, reject) => {
      const transaction = LocationsDB.db.transaction('Locations', 'readonly');
      transaction.oncomplete = (event: any) => {
        // resolve('success');
      };
      transaction.onerror = (event: any) => {
        reject(event.target.error);
      };
      const objectStore = transaction.objectStore('Locations');
      const request = objectStore.get(id);
      request.onsuccess = (event: any) => {
        resolve(event.target.result);
        // if (event.target.result) resolve(event.target.result);
        // reject('Error, could not find id.');
      };
      request.onerror = (event: any) => {
        reject(event.target.error);
      };
    });
  }
  static async updateLocation(location: any) {
    if (!LocationsDB.db) await LocationsDB.init();

    return new Promise((resolve, reject) => {
      const transaction = LocationsDB.db.transaction('Locations', 'readwrite');
      transaction.oncomplete = (event: any) => {
        resolve('success');
      };
      transaction.onerror = (event: any) => {
        reject(event.target.error);
      };
      const objectStore = transaction.objectStore('Locations');
      const request = objectStore.get(location.id);
      request.onsuccess = (event: any) => {
        if (event.target.result) {
          location.lowercaseName = location.name.toLowerCase();
          const requestUpdate = objectStore.put(location);
          requestUpdate.onerror = (event: any) => {
            reject(event.target.error);
          };
          requestUpdate.onsuccess = (event) => {
            resolve('updated data');
          };
        } else reject('Error, could not find id.');
      };
      request.onerror = (event: any) => {
        reject(event.target.error);
      };
    });
  }
  static async deleteLocation(id: number) {
    if (!LocationsDB.db) await LocationsDB.init();

    if (await LocationsDB.getLocation(id)) {
      return new Promise((resolve, reject) => {
        const transaction = LocationsDB.db.transaction('Locations', 'readwrite');
        transaction.oncomplete = (event: any) => {
          resolve('success');
        };
        transaction.onerror = (event: any) => {
          reject(event.target.error);
        };
        const objectStore = transaction.objectStore('Locations');
        const request = objectStore.delete(id);
        request.onsuccess = (event: any) => {
          resolve('deleted successfuly.');
          // if (event.target.result) resolve(event.target.result);
          // reject('Error, could not find id.');
        };
        request.onerror = (event: any) => {
          reject(event.target.error);
        };
      });
    } else {
      return new Promise((resolve, reject) => {
        resolve('id does not exist');
      });
    }
  }
}
