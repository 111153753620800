import moment from 'moment';

import { useState } from 'react';
import { Link } from 'react-router-dom';
import { LuLayoutDashboard } from 'react-icons/lu';

import { Button, PageHeader } from 'antd';
import { FilterTwoTone } from '@ant-design/icons';

import DashboardFilterDrawer from './DashboardFilterDrawer';
import { useDashboardMain } from '@/contexts/dashboard-main.context';

interface Props {
  username: string;
}

function DashboardHeader({ username }: Props) {
  const { locations, locationId, selectedDate, dashboardLayout } = useDashboardMain();
  const [showFilter, setShowFilter] = useState(false);

  const currentLocation = locations.find((location) => location.id === locationId);

  return (
    <div className="flex sm:items-center gap-2 justify-between">
      <div className="space-y-0">
        <PageHeader style={{ margin: 0, padding: 0 }} title={`Welcome ${username}!`} />
        <p className="text-gray-600 flex items-center gap-2 flex-wrap">
          {currentLocation && (
            <>
              <span>{currentLocation?.name}</span>
              <span>|</span>
            </>
          )}
          <span>
            {moment(selectedDate[0]).format('MMMM DD, YYYY (hh:mm a)')} -{' '}
            {moment(selectedDate[1]).format('MMMM DD, YYYY (hh:mm a)')}
          </span>
        </p>
      </div>

      <DashboardFilterDrawer show={showFilter} setShow={setShowFilter} />

      <div className="flex gap-1 sm:items-center mt-4 sm:mt-0">
        <Button
          disabled={locations.length === 0 || dashboardLayout.length === 0}
          onClick={() => setShowFilter(true)}
          className="!w-10 !rounded-sm disabled:opacity-80 disabled:cursor-not-allowed"
          style={{ border: 'var(--border-light)' }}
          icon={<FilterTwoTone style={{ fontSize: 20 }} className="[&>svg]:m-0" />}
        />

        <Link to="/customize">
          <Button
            type="primary"
            className="!flex justify-center items-center !w-10 !rounded-sm"
            icon={<LuLayoutDashboard size={20} />}
          />
        </Link>
      </div>
    </div>
  );
}

export default DashboardHeader;
