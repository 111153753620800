import {
  BankOutlined,
  CalendarOutlined,
  CarOutlined,
  CustomerServiceOutlined,
  DollarCircleOutlined,
  FieldTimeOutlined,
  FileTextOutlined,
  LockOutlined,
  MoneyCollectOutlined,
  PayCircleOutlined,
  PercentageOutlined,
  SettingOutlined,
  SwapOutlined,
  TransactionOutlined,
  UserOutlined
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { JsxElement } from 'typescript';
import ContactIcon from '../components/Icons/ContactIcon';
// import { DashboardIcon } from '../components/Icons/Dashboard';
import { LocationIcon } from '../components/Icons/LocationIcon';
// import ProductIcon from '../components/Icons/ProductIcon';
import { FaSolidUsers } from '../components/Icons/Users';
import history from '../utils/history.utils';
import RoutesIcon from './icons/Routes.svg';
import AdjustmentIcon from './icons/Adjustments.svg';
import ChannelIcon from './icons/Channel.svg';
import ReportIcon from './icons/Reports.svg';
import TaskIcon from './icons/Tasks.svg';
import OfferIcon from './icons/Offers.svg';
import SettingIcon from './icons/Settings.svg';
import UserIcon from './icons/Users.svg';
import SellIcon from './icons/Sell.svg';
import PurchaseIcon from './icons/Purchases.svg';
import ProcrumentIcon from './icons/Procruments.svg';
import DashboardIcon from './icons/Dashboard.svg';
import AclICon from './icons/ACL.svg';
import ProductIcon from './icons/Products.svg';
import EstimationIcon from './icons/Estimation.svg';
import { Tooltip } from 'antd';
import { checkAccess } from '../routes/acl';
import { SyntheticEvent } from 'react';
import { IClickValueTypes } from '../services/source/types';

// const menuorder: keys[] = [
//   // 'sqlSource',
//   'estimate',
//   // 'acl',
//   'dashboard',
//   'usermanagment',
//   'sell',
//   'task',
//   'transfers',
//   'offers',
//   'purchases',
//   // 'procurements',
//   'locations',
//   'products',
//   // 'contacts',
//   'routes',
//   'adjustments',
//   'channel',
//   'report',
//   'settings'
// ];

export const getmenuorders = () => {
  const childrenforhr = [
    {
      key: '/hrlist',
      label: <Link to="/hr">List</Link>,
      onClick: (val: IClickValueTypes) => {
        if (!val.domEvent.ctrlKey) {
          history.push({
            pathname: '/hr'
          });
        }
      }
    },
    {
      key: '/attendance',
      label: <Link to="/attendance">Attendance</Link>,
      onClick: (val: IClickValueTypes) => {
        if (!val.domEvent.ctrlKey) {
          history.push({
            pathname: '/attendance'
          });
        }
      }
    }
  ];

  const childrenforusermanagement = [
    {
      key: '/notification',
      label: <Link to="/notification">Notification</Link>,
      onClick: (val: IClickValueTypes) => {
        if (!val.domEvent.ctrlKey) {
          history.push({
            pathname: '/notification'
          });
        }
      }
    }
  ];

  if (checkAccess('ADMIN')) {
    childrenforusermanagement.push({
      key: '/acl',
      label: <Link to="/acl">ACL</Link>,
      onClick: (val: IClickValueTypes) => {
        if (!val.domEvent.ctrlKey) {
          history.push({
            pathname: '/acl'
          });
        }
      }
    });
  }

  if (checkAccess('READ_USER')) {
    childrenforusermanagement.push({
      key: '/users',
      label: <Link to="/users">User</Link>,
      onClick: (val: IClickValueTypes) => {
        if (!val.domEvent.ctrlKey) {
          history.push({
            pathname: '/users'
          });
        }
      }
    });
  }

  if (checkAccess('READ_USER')) {
    childrenforusermanagement.push({
      key: '/users/user-type',
      label: <Link to="/users/user-type">User Types</Link>,
      onClick: (val: IClickValueTypes) => {
        if (!val.domEvent.ctrlKey) {
          history.push({
            pathname: '/users/user-type'
          });
        }
      }
    });
  }

  if (checkAccess('READ_VENDOR')) {
    childrenforusermanagement.push({
      key: '/users/vendors',
      label: <Link to="/users/vendors">Suppliers</Link>,
      onClick: (val: IClickValueTypes) => {
        if (!val.domEvent.ctrlKey) {
          history.push({
            pathname: '/users/vendors'
          });
        }
      }
    });
  }

  if (checkAccess('READ_VENDOR_TYPE')) {
    childrenforusermanagement.push({
      key: 'vendor-type',
      label: <Link to="/vendor-type">Supplier Types</Link>,
      onClick: (val: IClickValueTypes) => {
        if (!val.domEvent.ctrlKey) {
          history.push({
            pathname: '/vendor-type'
          });
        }
      }
    });
  }

  if (checkAccess('READ_CUSTOMER')) {
    childrenforusermanagement.push({
      key: '/users/customers',
      label: <Link to="/users/customers">Customers</Link>,
      onClick: (val: IClickValueTypes) => {
        if (!val.domEvent.ctrlKey) {
          history.push({
            pathname: '/users/customers'
          });
        }
      }
    });
  }

  const childrenForBank = [];

  if (checkAccess('READ_BANK')) {
    childrenForBank.push({
      key: '/bank',
      label: <Link to="/bank">List</Link>,
      onClick: (val: IClickValueTypes) => {
        if (!val.domEvent.ctrlKey) {
          history.push({
            pathname: '/bank'
          });
        }
      }
    });
  }

  const childrenForSession = [];

  if (checkAccess('ADMIN')) {
    childrenForSession.push(
      {
        key: '/session',
        label: <Link to="/session">List</Link>,
        onClick: (val: IClickValueTypes) => {
          if (!val.domEvent.ctrlKey) {
            history.push({
              pathname: '/session'
            });
          }
        }
      },
      {
        key: '/session/money',
        label: <Link to="/session/money">Money</Link>,
        onClick: (val: IClickValueTypes) => {
          if (!val.domEvent.ctrlKey) {
            history.push({
              pathname: '/session/money'
            });
          }
        }
      }
    );
  }

  const childrenForAccounts = [];

  if (checkAccess('READ_ACCOUNT')) {
    childrenForAccounts.push(
      {
        key: '/accounts-rule',
        label: <Link to="/accounts-rule">Rule</Link>,
        onClick: (val: IClickValueTypes) => {
          if (!val.domEvent.ctrlKey) {
            history.push({
              pathname: '/accounts-rule'
            });
          }
        }
      },
      {
        key: '/accounts',
        label: <Link to="/accounts">Lists</Link>,
        onClick: (val: IClickValueTypes) => {
          if (!val.domEvent.ctrlKey) {
            history.push({
              pathname: '/accounts'
            });
          }
        }
      },
      {
        key: '/accounts/journal',
        label: <Link to="/accounts/journal">Journal</Link>,
        onClick: (val: IClickValueTypes) => {
          if (!val.domEvent.ctrlKey) {
            history.push({
              pathname: '/accounts/journal'
            });
          }
        }
      },
      {
        key: '/accounts/journal-lines',
        label: <Link to="/accounts/journal-lines">Journal Lines</Link>,
        onClick: (val: IClickValueTypes) => {
          if (!val.domEvent.ctrlKey) {
            history.push({
              pathname: '/accounts/journal-lines'
            });
          }
        }
      },
      {
        key: '/accounts/new-journal-lines',
        label: <Link to="/accounts/new-journal-lines">New Journal Lines</Link>,
        onClick: (val: IClickValueTypes) => {
          if (!val.domEvent.ctrlKey) {
            history.push({
              pathname: '/accounts/new-journal-lines'
            });
          }
        }
      },
      {
        key: '/accounts/view',
        label: <Link to="/accounts/view">User Ledger</Link>,
        onClick: (val: IClickValueTypes) => {
          if (!val.domEvent.ctrlKey) {
            history.push({
              pathname: '/accounts/view'
            });
          }
        }
      },
      {
        key: '/ledger',
        label: <Link to="/ledger">Ledger</Link>,
        onClick: (val: IClickValueTypes) => {
          if (!val.domEvent.ctrlKey) {
            history.push({
              pathname: '/ledger'
            });
          }
        }
      },
      {
        key: '/trial-balance',
        label: <Link to="/trial-balance">Trial Balance</Link>,
        onClick: (val: IClickValueTypes) => {
          if (!val.domEvent.ctrlKey) {
            history.push({
              pathname: '/trial-balance'
            });
          }
        }
      },
      {
        key: '/profit-loss',
        label: <Link to="/profit-loss">Profit & Loss</Link>,
        onClick: (val: IClickValueTypes) => {
          if (!val.domEvent.ctrlKey) {
            history.push({
              pathname: '/profit-loss'
            });
          }
        }
      },
      {
        key: '/balance-sheet',
        label: <Link to="/balance-sheet">Balance Sheet</Link>,
        onClick: (val: IClickValueTypes) => {
          if (!val.domEvent.ctrlKey) {
            history.push({
              pathname: '/balance-sheet'
            });
          }
        }
      }
    );
  }

  const childrenforproducts = [];

  if (checkAccess('READ_PRODUCT')) {
    childrenforproducts.push({
      key: '/products',
      label: <Link to="/products">List</Link>,
      onClick: (val: IClickValueTypes) => {
        if (!val.domEvent.ctrlKey) {
          history.push({
            pathname: '/products'
          });
        }
      }
    });
  }

  if (checkAccess('READ_PRODUCT')) {
    childrenforproducts.push({
      key: '/products/archive',
      label: <Link to="/products/archive">Archived List</Link>,
      onClick: (val: IClickValueTypes) => {
        if (!val.domEvent.ctrlKey) {
          history.push({
            pathname: '/products/archive'
          });
        }
      }
    });
  }

  if (checkAccess('READ_PRODUCT_CATEGORY')) {
    childrenforproducts.push({
      key: '/products/category',
      label: <Link to="/products/category">Category</Link>,
      onClick: (val: IClickValueTypes) => {
        if (!val.domEvent.ctrlKey) {
          history.push({
            pathname: '/products/category'
          });
        }
      }
    });
  }

  if (checkAccess('READ_PRODUCT_LOTS')) {
    childrenforproducts.push(
      {
        key: '/products/lots',
        label: <Link to="/products/lots">Lots</Link>,
        onClick: (val: IClickValueTypes) => {
          if (!val.domEvent.ctrlKey) {
            history.push({
              pathname: '/products/lots'
            });
          }
        }
      },
      {
        key: '/products/previous-lots',
        label: <Link to="/products/previous-lots">Previous Lots</Link>,
        onClick: (val: IClickValueTypes) => {
          if (!val.domEvent.ctrlKey) {
            history.push({
              pathname: '/products/previous-lots'
            });
          }
        }
      }
    );
  }

  if (checkAccess('CONVERT_LOTS')) {
    childrenforproducts.push({
      key: '/products/convertlots',
      label: <Link to="/products/convertlots">Convert Lots</Link>,
      onClick: (val: IClickValueTypes) => {
        if (!val.domEvent.ctrlKey) {
          history.push({
            pathname: '/products/convertlots'
          });
        }
      }
    });
  }

  if (checkAccess('READ_PRODUCT_LOTS')) {
    childrenforproducts.push({
      key: '/products/totallots',
      label: <Link to="/products/totallots">Total Lots</Link>,
      onClick: (val: IClickValueTypes) => {
        if (!val.domEvent.ctrlKey) {
          history.push({
            pathname: '/products/totallots'
          });
        }
      }
    });
  }

  childrenforproducts.push(
    {
      key: '/products/soldtotal',
      label: <Link to="/products/soldtotal">Total Sold</Link>,
      onClick: (val: IClickValueTypes) => {
        if (!val.domEvent.ctrlKey) {
          history.push({
            pathname: '/products/soldtotal'
          });
        }
      }
    },
    {
      key: '/products/purchasetotal',
      label: <Link to="/products/purchasetotal">Total Purchase</Link>,
      onClick: (val: IClickValueTypes) => {
        if (!val.domEvent.ctrlKey) {
          history.push({
            pathname: '/products/purchasetotal'
          });
        }
      }
    }
  );

  const childrenforopenstock = [];

  if (checkAccess('CREATE_OPENING_STOCK')) {
    childrenforopenstock.push({
      key: '/open-stock-create',
      label: <Link to={'/open-stock'}>Create</Link>,
      onClick: (val: IClickValueTypes) => {
        if (!val.domEvent.ctrlKey) {
          history.push({
            pathname: '/open-stock'
          });
        }
      }
    });
    childrenforopenstock.push({
      key: '/open-stock-download-upload',
      label: (
        <Tooltip title={'Download/Upload'} color="blue">
          <Link to={'/open-stock'}>Download/Upload</Link>
        </Tooltip>
      ),
      onClick: (val: IClickValueTypes) => {
        if (!val.domEvent.ctrlKey) {
          history.push({
            pathname: '/open-stock-download-upload'
          });
        }
      }
    });
  }

  if (childrenforopenstock.length > 0) {
    childrenforproducts.push({
      key: '/open-stock',
      label: 'Open Stock',
      children: childrenforopenstock
    });
  }

  const childrenforpricegroups = [];
  if (checkAccess('READ_PRICE_GROUP')) {
    childrenforpricegroups.push({
      key: '/price-groups',
      label: <Link to={'/price-groups'}>List</Link>,
      onClick: (val: IClickValueTypes) => {
        if (!val.domEvent.ctrlKey) {
          history.push({
            pathname: '/price-groups'
          });
        }
      }
    });

    childrenforpricegroups.push({
      key: '/price-groups-download',
      label: <Link to={'/price-groups-download'}>Download</Link>,
      onClick: (val: IClickValueTypes) => {
        if (!val.domEvent.ctrlKey) {
          history.push({
            pathname: '/price-groups-download'
          });
        }
      }
    });
  }

  if (checkAccess('CREATE_PRICE_GROUP')) {
    childrenforpricegroups.push({
      key: '/price-groups-new',
      label: <Link to={'/price-groups-new'}>Create</Link>,
      onClick: (val: IClickValueTypes) => {
        if (!val.domEvent.ctrlKey) {
          history.push({
            pathname: '/price-groups-new'
          });
        }
      }
    });

    childrenforpricegroups.push({
      key: '/price-groups-upload',
      label: <Link to="/price-groups-upload">Upload</Link>,
      onClick: (val: IClickValueTypes) => {
        if (!val.domEvent.ctrlKey) {
          history.push({
            pathname: '/price-groups-upload'
          });
        }
      }
    });
  }

  if (checkAccess('UPDATE_PRODUCT')) {
    childrenforproducts.push({
      key: '/product-status',
      label: <Link to="/products/status">Product Status</Link>,
      onClick: (val: IClickValueTypes) => {
        if (!val.domEvent.ctrlKey) {
          history.push({
            pathname: '/products/status'
          });
        }
      }
    });
  }

  if (checkAccess('READ_PRODUCT')) {
    childrenforproducts.push({
      key: '/product-history',
      label: <Link to="/products/history">Product History</Link>,
      onClick: (val: IClickValueTypes) => {
        if (!val.domEvent.ctrlKey) {
          history.push({
            pathname: '/products/history'
          });
        }
      }
    });
    childrenforproducts.push({
      key: '/product-download',
      label: <Link to="/product-download">Product download</Link>,
      onClick: (val: IClickValueTypes) => {
        if (!val.domEvent.ctrlKey) {
          history.push({
            pathname: '/product-download'
          });
        }
      }
    });
    childrenforproducts.push({
      key: '/vendor-products',
      label: <Link to="/vendor-products">Vendor Products</Link>,
      onClick: (val: IClickValueTypes) => {
        if (!val.domEvent.ctrlKey) {
          history.push({
            pathname: '/vendor-products'
          });
        }
      }
    });
  }

  if (childrenforpricegroups.length > 0) {
    childrenforproducts.push({
      key: '/products/groups',
      label: 'Price Groups',
      children: childrenforpricegroups
    });
  }

  const childrenforpurchase = [];

  if (checkAccess('READ_PURCHASE')) {
    childrenforpurchase.push(
      {
        key: '/purchase',
        label: <Link to="/purchase">List</Link>,
        onClick: (val: IClickValueTypes) => {
          if (!val.domEvent.ctrlKey) {
            history.push({
              pathname: '/purchase'
            });
          }
        }
      },
      {
        key: '/purchase/return',
        label: <Link to="/purchase/return">Return</Link>,
        onClick: (val: IClickValueTypes) => {
          if (!val.domEvent.ctrlKey) {
            history.push({
              pathname: '/purchase/return'
            });
          }
        }
      }
    );
  }

  if (checkAccess('READ_PURCHASE_ORDER')) {
    childrenforpurchase.push({
      key: '/purchase/order',
      label: <Link to="/purchase/order">Order</Link>,
      onClick: (val: IClickValueTypes) => {
        if (!val.domEvent.ctrlKey) {
          history.push({
            pathname: '/purchase/order'
          });
        }
      }
    });
  }

  if (checkAccess('READ_PURCHASE') && checkAccess('READ_PURCHASE_ORDER')) {
    childrenforpurchase.push({
      key: '/purchase/purchase-list',
      label: <Link to="/purchase/purchase-list">Purchase Demand</Link>,
      onClick: (val: IClickValueTypes) => {
        if (!val.domEvent.ctrlKey) {
          history.push({
            pathname: '/purchase/purchase-list'
          });
        }
      }
    });
    childrenforpurchase.push({
      key: 'purchase/purchase-list/view',
      label: (
        <Tooltip title="Purchase Vs Purchase Demand" color="blue">
          {' '}
          <Link to="/purchase/purchase-list/view">Purchase vs Purchase Demand</Link>
        </Tooltip>
      ),
      onClick: (val: IClickValueTypes) => {
        if (!val.domEvent.ctrlKey) {
          history.push({
            pathname: '/purchase/purchase-list/view'
          });
        }
      }
    });
    childrenforpurchase.push({
      key: '/stock-estimate-vs-purchase-demand',
      label: (
        <Tooltip title="Stock Estimate Vs Purchase Demand" color="blue">
          <Link to="/sell-estimate-vs-purchase-demand">Stock Estimate Vs Purchase Demand</Link>
        </Tooltip>
      ),
      onClick: (val: IClickValueTypes) => {
        if (!val.domEvent.ctrlKey) {
          history.push({
            pathname: '/sell-estimate-vs-purchase-demand'
          });
        }
      }
    });
  }

  const childrenforoffers = [];

  // if (checkAccess('READ_OFFER')) {
  //   childrenforoffers.push({
  //     key: '/offer',
  //     label: <Link to="/offer">List</Link>,
  //     onClick: (val: IClickValueTypes) => {
  //       if (!val.domEvent.ctrlKey) {
  //         history.push({
  //           pathname: '/offer'
  //         });
  //       }
  //     }
  //   });
  // }

  // if (checkAccess('CREATE_OFFER')) {
  //   childrenforoffers.push({
  //     key: '/offer/upload',
  //     label: <Link to="/offer/upload">Upload</Link>,
  //     onClick: (val: IClickValueTypes) => {
  //       if (!val.domEvent.ctrlKey) {
  //         history.push({
  //           pathname: '/offer/upload'
  //         });
  //       }
  //     }
  //   });
  // }

  if (checkAccess('READ_CUSTOMER_GROUP')) {
    childrenforusermanagement.push({
      key: '/offer/customer-group',
      label: <Link to="/offer/customer-group">Customer Group</Link>,
      onClick: (val: IClickValueTypes) => {
        if (!val.domEvent.ctrlKey) {
          history.push({
            pathname: '/offer/customer-group'
          });
        }
      }
    });
  }

  if (checkAccess('READ_DISCOUNT')) {
    childrenforoffers.push(
      {
        key: '/offer/discount',
        label: <Link to="/offer/discount">Customer Group</Link>,
        onClick: (val: IClickValueTypes) => {
          if (!val.domEvent.ctrlKey) {
            history.push({
              pathname: '/offer/discount'
            });
          }
        }
      },
      {
        key: '/offer/discount-category',
        label: <Link to="/offer/discount-category">Product Category</Link>,
        onClick: (val: IClickValueTypes) => {
          if (!val.domEvent.ctrlKey) {
            history.push({
              pathname: '/offer/discount-category'
            });
          }
        }
      },
      {
        key: '/offer/discount-product',
        label: <Link to="/offer/discount-product">Product Discount</Link>,
        onClick: (val: IClickValueTypes) => {
          if (!val.domEvent.ctrlKey) {
            history.push({
              pathname: '/offer/discount-product'
            });
          }
        }
      }
    );
  }

  const childrenforsell = [];

  if (checkAccess('READ_SELL')) {
    childrenforsell.push({
      key: '/sell',
      label: <Link to="/sell">List</Link>,
      onClick: (val: IClickValueTypes) => {
        if (!val.domEvent.ctrlKey) {
          history.push({
            pathname: '/sell'
          });
        }
      }
    });
  }

  if (checkAccess('READ_SELL_ORDER')) {
    childrenforsell.push({
      key: '/sell/order',
      label: <Link to="/sell/order">Sell Order</Link>,
      onClick: (val: IClickValueTypes) => {
        if (!val.domEvent.ctrlKey) {
          history.push({
            pathname: '/sell/order'
          });
        }
      }
    });
  }

  childrenforsell.push({
    key: '/pay/add',
    label: 'Add Payment',
    onClick: (val: IClickValueTypes) => {
      if (!val.domEvent.ctrlKey) {
        history.push({
          pathname: '/pay/add'
        });
      }
    }
  });

  if (checkAccess('READ_SELL_RETURN')) {
    childrenforsell.push({
      key: '/sell/return',
      label: <Link to="/sell/return">Sell Return</Link>,
      onClick: (val: IClickValueTypes) => {
        if (!val.domEvent.ctrlKey) {
          history.push({
            pathname: '/sell/return'
          });
        }
      }
    });
  }

  if (checkAccess('CREATE_POS')) {
    childrenforsell.push({
      key: '/sell/pos-create-v2',
      label: <Link to="/sell/pos-create-v2">POS</Link>,
      onClick: (val: IClickValueTypes) => {
        if (!val.domEvent.ctrlKey) {
          history.push({
            pathname: '/sell/pos-create-v2'
          });
        }
      }
    });
  }

  if (checkAccess('READ_SELL') && checkAccess('READ_SELL_ORDER')) {
    childrenforsell.push({
      key: '/sell/sell-list',
      label: <Link to="/sell/sell-list">Estimate Stock</Link>,
      onClick: (val: IClickValueTypes) => {
        if (!val.domEvent.ctrlKey) {
          history.push({
            pathname: '/sell/sell-list'
          });
        }
      }
    });

    childrenforsell.push({
      key: 'sell/sell-list/view',
      label: (
        <Tooltip title="Order Vs Estimated Stock" color="blue">
          {' '}
          <Link to="/sell/sell-list/view">Order Vs Estimated Stock</Link>
        </Tooltip>
      ),
      onClick: (val: IClickValueTypes) => {
        if (!val.domEvent.ctrlKey) {
          history.push({
            pathname: '/sell/sell-list/view'
          });
        }
      }
    });
    childrenforsell.push({
      key: '/sell-estimate-vs-purchase-demand',
      label: (
        <Tooltip title="Stock Estimate Vs Purchase Demand" color="blue">
          <Link to="/sell-estimate-vs-purchase-demand">Stock Estimate Vs Purchase Demand</Link>
        </Tooltip>
      ),
      onClick: (val: IClickValueTypes) => {
        if (!val.domEvent.ctrlKey) {
          history.push({
            pathname: '/sell-estimate-vs-purchase-demand'
          });
        }
      }
    });
  }

  const childrenforadjustments = [];

  if (checkAccess('READ_ADJUSTMENT')) {
    childrenforadjustments.push({
      key: '/adjustments',
      label: <Link to="/adjustments">List</Link>,
      onClick: (val: IClickValueTypes) => {
        if (!val.domEvent.ctrlKey) {
          history.push({
            pathname: '/adjustments'
          });
        }
      }
    });
  }

  // if (checkAccess('READ_ADJUSTMENT')) {
  //   childrenforadjustments.push({
  //     key: '/adjustments/pending-approval',
  //     label: <Link to="/adjustments/pending-approval">Pending Approval</Link>,
  //     onClick: () => {
  //       history.push({
  //         pathname: '/adjustments/pending-approval'
  //       });
  //     }
  //   });
  // }

  if (checkAccess('READ_REASON')) {
    childrenforadjustments.push({
      key: '/adjustments/reasons',
      label: <Link to="/adjustments/reasons">Reasons</Link>,
      onClick: (val: IClickValueTypes) => {
        if (!val.domEvent.ctrlKey) {
          history.push({
            pathname: '/adjustments/reasons'
          });
        }
      }
    });
  }

  const childrenForEpay = [];

  if (checkAccess('ADMIN')) {
    childrenForEpay.push({
      key: '/fonepay',
      label: <Link to="/fonepay">Fonepay</Link>,
      onClick: (val: IClickValueTypes) => {
        if (!val.domEvent.ctrlKey) {
          history.push({
            pathname: '/fonepay'
          });
        }
      }
    });
  }

  const newmenuorder: any[] = [
    'dashboard',
    // 'routes',
    // 'estimate',
    'usermanagment'
    // 'hr'
    // 'vehicle'
    // 'locations',
    // 'estimate',
    // 'products',
    // 'offers',
    // 'sell',
    // 'purchases',
    // 'adjustments',
    // 'transfers',
    // 'report',
    // 'task',
    // 'channel',
    // 'routes',
    // 'settings'
  ];

  if (checkAccess('ADMIN')) {
    newmenuorder.push('hr');
  }

  if (checkAccess('READ_WALLET')) {
    newmenuorder.push('wallet');
  }

  const childrenfortracking = [];
  if (checkAccess('READ_USER')) {
    childrenfortracking.push({
      key: '/users/all-live',
      label: <Link to="/users/all-live">User All Live</Link>,
      onClick: (val: IClickValueTypes) => {
        if (!val.domEvent.ctrlKey) {
          history.push({
            pathname: '/users/all-live'
          });
        }
      }
    });
  }
  childrenfortracking.push({
    key: '/users/history',
    label: <Link to="/users/history">User History</Link>,
    onClick: (val: IClickValueTypes) => {
      if (!val.domEvent.ctrlKey) {
        history.push({
          pathname: '/users/history'
        });
      }
    }
  });

  const childrenforvehicle = [];

  if (checkAccess('READ_VEHICLE')) {
    childrenforvehicle.push({
      key: '/vehicle',
      label: <Link to="/vehicle">List</Link>,
      onClick: (val: IClickValueTypes) => {
        if (!val.domEvent.ctrlKey) {
          history.push({
            pathname: '/vehicle'
          });
        }
      }
    });
  }
  childrenforvehicle.push({
    key: '/vehicle-usage',
    label: <Link to="/vehicle-usage">Vehicle Usage</Link>,
    onClick: (val: IClickValueTypes) => {
      if (!val.domEvent.ctrlKey) {
        history.push({
          pathname: '/vehicle-usage'
        });
      }
    }
  });
  childrenforvehicle.push({
    key: '/vehicle-maintenance',
    label: <Link to="/vehicle-maintenance">Vehicle Maintenance</Link>,
    onClick: (val: IClickValueTypes) => {
      if (!val.domEvent.ctrlKey) {
        history.push({
          pathname: '/vehicle-maintenance'
        });
      }
    }
  });
  childrenforvehicle.push({
    key: '/vehicle-fuel',
    label: <Link to="/vehicle-fuel">Vehicle Fuel</Link>,
    onClick: (val: IClickValueTypes) => {
      if (!val.domEvent.ctrlKey) {
        history.push({
          pathname: '/vehicle-fuel'
        });
      }
    }
  });

  if (childrenforvehicle.length > 0) {
    childrenfortracking.push({
      key: '/vehicle',
      label: 'Vehicle',
      children: childrenforvehicle
    });
  }

  if (childrenfortracking.length > 0) {
    newmenuorder.push('tracking');
  }
  if (checkAccess('READ_LOCATION')) {
    newmenuorder.push('locations');
  }

  if (childrenForBank.length > 0) {
    newmenuorder.push('bank');
  }

  if (childrenForSession.length > 0) {
    newmenuorder.push('session');
  }

  if (childrenForAccounts.length > 0) {
    newmenuorder.push('accounts');
  }

  if (childrenforproducts.length > 0) {
    newmenuorder.push('products');
  }

  if (childrenforoffers.length > 0) {
    newmenuorder.push('offers');
  }

  if (childrenforsell.length > 0) {
    newmenuorder.push('sell');
  }

  if (childrenforpurchase.length > 0) {
    newmenuorder.push('purchases');
  }
  if (checkAccess('ADMIN')) {
    newmenuorder.push('ird');
  }
  if (checkAccess('READ_EXPENSE')) {
    newmenuorder.push('expense');
  }

  if (checkAccess('READ_LOGS')) {
    newmenuorder.push('logs');
  }

  if (childrenforadjustments.length > 0) {
    newmenuorder.push('adjustments');
  }

  if (checkAccess('READ_TRANSFER')) {
    newmenuorder.push('transfers');
  }

  newmenuorder.push('report', 'task');

  if (checkAccess('READ_CHANNEL')) {
    newmenuorder.push('channel');
  }

  if (checkAccess('READ_ROUTE')) {
    newmenuorder.push('routes');
  }

  if (childrenForEpay.length > 0) {
    newmenuorder.push('epay');
  }

  newmenuorder.push('settings');

  const childrenforsettings = [
    {
      key: '/invoices',
      label: <Link to="/invoices">Invoices</Link>,
      onClick: (val: IClickValueTypes) => {
        if (!val.domEvent.ctrlKey) {
          history.push({
            pathname: '/invoices'
          });
        }
      }
    },
    {
      key: '/settings/preferences',
      label: <Link to="/settings/preferences">Preferences</Link>,
      onClick: (val: IClickValueTypes) => {
        if (!val.domEvent.ctrlKey) {
          history.push({
            pathname: '/settings/preferences'
          });
        }
      }
    },
    {
      key: '/daily-rate',
      label: <Link to="/daily-rate">Daily Rate</Link>,
      onClick: (val: IClickValueTypes) => {
        if (!val.domEvent.ctrlKey) {
          history.push({
            pathname: '/daily-rate'
          });
        }
      }
    },
    {
      key: '/settings/menu',
      label: <Link to="/settings/menu">Menu</Link>,
      onClick: (val: IClickValueTypes) => {
        if (!val.domEvent.ctrlKey) {
          history.push({
            pathname: '/settings/menu'
          });
        }
      }
    }
  ];

  if (checkAccess('CREATE_GLOBAL_SETTINGS')) {
    childrenforsettings.push({
      key: '/settings/global',
      label: <Link to="/settings/global">Global</Link>,
      onClick: (val: IClickValueTypes) => {
        if (!val.domEvent.ctrlKey) {
          history.push({
            pathname: '/settings/global'
          });
        }
      }
    });
  }

  let childrenForReports: any[] = [
    {
      key: '/source',
      label: <Link to="/source">Dev tools</Link>,
      onClick: (val: IClickValueTypes) => {
        if (!val.domEvent.ctrlKey) {
          history.push({
            pathname: '/source'
          });
        }
      }
    },
    {
      key: '/source/new',
      label: <Link to="/source/new">Create</Link>,
      onClick: (val: IClickValueTypes) => {
        if (!val.domEvent.ctrlKey) {
          history.push({
            pathname: '/source/new'
          });
        }
      }
    },
    {
      key: '/reports',
      // icon: <img src={ReportIcon} className="menu-icons" />,
      label: <Link to="/reports">List</Link>,
      onClick: (val: IClickValueTypes) => {
        if (!val.domEvent.ctrlKey) {
          history.push({
            pathname: '/reports'
          });
        }
      }
    }
  ];

  const childrenforchannel: any = [
    {
      key: '/channel',
      label: <Link to="/channel">List</Link>,
      onClick: (val: IClickValueTypes) => {
        if (!val.domEvent.ctrlKey) {
          history.push({
            pathname: '/channel'
          });
        }
      }
    },
    {
      key: '/channel/closed',
      label: 'Closed',
      onClick: (val: IClickValueTypes) => {
        if (!val.domEvent.ctrlKey) {
          history.push({
            pathname: '/channel/closed'
          });
        }
      }
    }
  ];

  const childrenforExpense: any = [
    {
      key: '/expense',
      label: <Link to="expense">List</Link>,
      onClick: (val: IClickValueTypes) => {
        if (!val.domEvent.ctrlKey) {
          history.push({
            pathname: '/expense'
          });
        }
      }
    },
    {
      key: '/expense-category',
      label: <Link to="expense-category">Category</Link>,
      onClick: (val: IClickValueTypes) => {
        if (!val.domEvent.ctrlKey) {
          history.push({
            pathname: '/expense-category'
          });
        }
      }
    }
  ];

  const childrenforIrd: any = [
    {
      key: '/ird/materialized-report',
      label: <Link to="materialized-report">Materialized View Report</Link>,
      onClick: (val: IClickValueTypes) => {
        if (!val.domEvent.ctrlKey) {
          history.push({
            pathname: '/ird/materialized-report'
          });
        }
      }
    },
    {
      key: '/ird/sales-register',
      label: <Link to="/ird/sales-register">Sales Register</Link>,
      onClick: (val: IClickValueTypes) => {
        if (!val.domEvent.ctrlKey) {
          history.push({
            pathname: '/ird/sales-register'
          });
        }
      }
    },
    {
      key: '/ird/sales-return-register',
      label: <Link to="/ird/sales-return-register">Sales Return Register</Link>,
      onClick: (val: IClickValueTypes) => {
        if (!val.domEvent.ctrlKey) {
          history.push({
            pathname: '/ird/sales-return-register'
          });
        }
      }
    },
    {
      key: '/ird/purchase-register',
      label: <Link to="/ird/purchase-register">Purchase Register</Link>,
      onClick: (val: IClickValueTypes) => {
        if (!val.domEvent.ctrlKey) {
          history.push({
            pathname: '/ird/purchase-register'
          });
        }
      }
    },
    {
      key: '/ird/purchase-return-register',
      label: <Link to="/ird/purchase-return-register">Purchase Return Register</Link>,
      onClick: (val: IClickValueTypes) => {
        if (!val.domEvent.ctrlKey) {
          history.push({
            pathname: '/ird/purchase-return-register'
          });
        }
      }
    },
    {
      key: '/ird/logs',
      label: <Link to="/ird/logs">Logs</Link>,
      onClick: (val: IClickValueTypes) => {
        if (!val.domEvent.ctrlKey) {
          history.push({
            pathname: '/ird/logs'
          });
        }
      }
    },
    {
      key: '/ird-logs/events',
      label: <Link to="/ird-logs/events">Events</Link>,
      onClick: (val: IClickValueTypes) => {
        if (!val.domEvent.ctrlKey) {
          history.push({
            pathname: '/ird-logs/events'
          });
        }
      }
    },
    {
      key: '/ird/vat-accounts',
      label: <Link to="/ird/vat-accounts">VAT Accounts</Link>,
      onClick: (val: IClickValueTypes) => {
        if (!val.domEvent.ctrlKey) {
          history.push({
            pathname: '/ird/vat-accounts'
          });
        }
      }
    }
  ];

  const childrenforSystemLogs: any = [
    {
      key: '/logs',
      label: <Link to="logs">List</Link>,
      onClick: (val: IClickValueTypes) => {
        if (!val.domEvent.ctrlKey) {
          history.push({
            pathname: '/logs'
          });
        }
      }
    },
    {
      key: '/log-events',
      label: <Link to="log-events">Events</Link>,
      onClick: (val: IClickValueTypes) => {
        if (!val.domEvent.ctrlKey) {
          history.push({
            pathname: '/log-events'
          });
        }
      }
    },
    {
      key: '/log-backup',
      label: <Link to="log-events">Backup</Link>,
      onClick: (val: IClickValueTypes) => {
        if (!val.domEvent.ctrlKey) {
          history.push({
            pathname: '/logs/backup'
          });
        }
      }
    }
  ];

  if (checkAccess('READ_REASON')) {
    childrenforchannel.push({
      key: '/channel/reasons',
      label: 'Reasons',
      onClick: (val: IClickValueTypes) => {
        if (!val.domEvent.ctrlKey) {
          history.push({
            pathname: '/channel/reasons'
          });
        }
      }
    });
  }

  if (process.env.NODE_ENV === 'production') {
    childrenForReports = [];
  }

  const mapper = {
    dashboard: {
      key: '1',
      icon: <img src={DashboardIcon} className="menu-icons" />,
      label: <div>Dashboard</div>,
      children: [
        {
          key: '/home',
          label: <Link to="/">Home</Link>,
          onClick: (val: IClickValueTypes) => {
            if (!val.domEvent.ctrlKey) {
              history.push({
                pathname: '/'
              });
            }
          }
        },
        {
          key: '/dashboard/custom',
          label: <div>Custom</div>,
          onClick: () => {
            window.open('https://reports.freshktm.com/', '_blank');
          }
        }
      ]
    },
    hr: {
      key: '2',
      icon: <img src={UserIcon} className="menu-icons" />,
      label: 'HR Management',
      children: childrenforhr
    },
    usermanagment: {
      key: '3',
      icon: <img src={UserIcon} className="menu-icons" />,
      label: 'User Management',
      children: childrenforusermanagement
    },
    contacts: {
      key: '4',
      icon: <ContactIcon />,
      label: <Link to="/contacts">Contacts</Link>,
      onClick: (val: IClickValueTypes) => {
        if (!val.domEvent.ctrlKey) {
          history.push({
            pathname: '/contacts'
          });
        }
      }
    },
    products: {
      key: '5',
      icon: <img src={ProductIcon} className="menu-icons" />,
      label: 'Products',
      children: childrenforproducts
    },

    locations: {
      key: '6',
      icon: <LocationIcon />,
      label: 'Locations',
      children: [
        {
          key: '/locations',
          label: <Link to="/locations">Lists</Link>,
          onClick: (val: IClickValueTypes) => {
            if (!val.domEvent.ctrlKey) {
              history.push({
                pathname: '/locations'
              });
            }
          }
        }
      ]
    },
    procurements: {
      key: '7',
      icon: <img src={ProcrumentIcon} className="menu-icons" />,
      label: <Link to="/procurements">Procurements</Link>,
      onClick: (val: IClickValueTypes) => {
        if (!val.domEvent.ctrlKey) {
          history.push({
            pathname: '/procurements'
          });
        }
      }
    },
    purchases: {
      key: '8',
      icon: <img src={PurchaseIcon} className="menu-icons" />,
      label: 'Purchases',
      children: childrenforpurchase
    },
    settings: {
      key: '9',
      icon: <img src={SettingIcon} className="menu-icons" />,
      label: 'Settings',
      children: childrenforsettings
    },
    offers: {
      key: '10',
      icon: <img src={OfferIcon} className="menu-icons" />,
      label: 'Offers',
      children: childrenforoffers
    },
    routes: {
      key: '11',
      icon: <img src={RoutesIcon} className="menu-icons" />,
      label: 'Routes',
      children: [
        {
          key: '/routes',
          label: <Link to="/routes">List</Link>,
          onClick: () => {
            history.push({
              pathname: '/routes'
            });
          }
        }
      ]
    },
    sell: {
      key: '12',
      icon: <img src={SellIcon} className="menu-icons" />,
      label: 'Sell',
      children: childrenforsell
    },
    task: {
      key: '13',
      // icon: < />,
      label: 'Tasks',
      icon: <img src={TaskIcon} className="menu-icons" />,
      children: [
        {
          key: '/tasks',
          label: <Link to="/tasks">List</Link>,
          onClick: (val: IClickValueTypes) => {
            if (!val.domEvent.ctrlKey) {
              history.push({
                pathname: '/tasks'
              });
            }
          }
        },
        {
          key: '/tasks/archived',
          label: <Link to="/tasks/archived">Archived</Link>,
          onClick: (val: IClickValueTypes) => {
            if (!val.domEvent.ctrlKey) {
              history.push({
                pathname: '/tasks/archived'
              });
            }
          }
        }
      ]
    },
    adjustments: {
      key: '14',
      icon: <img src={AdjustmentIcon} className="menu-icons" />,
      label: 'Adjustments',
      children: childrenforadjustments
    },
    transfers: {
      key: '15',
      icon: <SwapOutlined />,
      label: 'Transfers',
      children: [
        {
          key: '/transfers',
          label: <Link to="/transfers">List</Link>,
          onClick: (val: IClickValueTypes) => {
            if (!val.domEvent.ctrlKey) {
              history.push({
                pathname: '/transfers'
              });
            }
          }
        },
        {
          key: '/transfer-in',
          label: <Link to="/transfer-in">Transfer In</Link>,
          onClick: (val: IClickValueTypes) => {
            if (!val.domEvent.ctrlKey) {
              history.push({
                pathname: '/transfer-in'
              });
            }
          }
        }
      ]
    },
    channel: {
      key: '16',
      icon: <img src={ChannelIcon} className="menu-icons" />,
      label: 'Tickets',
      children: childrenforchannel
    },
    report: {
      key: '17',
      label: 'Reports',
      icon: <img src={ReportIcon} className="menu-icons" />,
      children: [
        {
          key: '/reports/payment-sell',
          label: (
            <Tooltip title="Sell Payment Report" color="blue">
              <Link to="/reports/payment-sell">Sell Payment Report</Link>
            </Tooltip>
          ),
          onClick: (val: IClickValueTypes) => {
            if (!val.domEvent.ctrlKey) {
              history.push({
                pathname: '/reports/payment-sell'
              });
            }
          }
        },
        {
          key: '/reports/payment-purchase',
          label: (
            <Tooltip title="Purchase Payment Report" color="blue">
              {' '}
              <Link to="/reports/payment-purchase">Purchase Payment Report</Link>
            </Tooltip>
          ),
          onClick: (val: IClickValueTypes) => {
            if (!val.domEvent.ctrlKey) {
              history.push({
                pathname: '/reports/payment-purchase'
              });
            }
          }
        },
        {
          key: '/reports/total-sale-and-purchase',
          label: (
            <Tooltip title="Total Sale And Purchase Report" color="blue">
              {' '}
              <Link to="/reports/total-sale-and-purchase">Total Sale And Purchase Report</Link>
            </Tooltip>
          ),
          onClick: (val: IClickValueTypes) => {
            if (!val.domEvent.ctrlKey) {
              history.push({
                pathname: '/reports/total-sale-and-purchase'
              });
            }
          }
        },
        {
          key: '/reports/stock-adjustment',
          label: (
            <Tooltip title="Stock Adjustment Report" color="blue">
              <Link to="/reports/stock-adjustment">Stock Adjustment Report</Link>
            </Tooltip>
          ),
          onClick: (val: IClickValueTypes) => {
            if (!val.domEvent.ctrlKey) {
              history.push({
                pathname: '/reports/stock-adjustment'
              });
            }
          }
        },
        {
          key: '/reports/stock-value',
          label: (
            <Tooltip title="Stock Value Report" color="blue">
              <Link to="/reports/stock-adjustment">Stock Value Report</Link>
            </Tooltip>
          ),
          onClick: (val: IClickValueTypes) => {
            if (!val.domEvent.ctrlKey) {
              history.push({
                pathname: '/reports/stock-value'
              });
            }
          }
        },
        {
          key: '/reports/product-sell',
          label: (
            <Tooltip title="Product Sell Report" color="blue">
              {' '}
              <Link to="/reports/product-sell">Product Sell Report</Link>
            </Tooltip>
          ),
          onClick: (val: IClickValueTypes) => {
            if (!val.domEvent.ctrlKey) {
              history.push({
                pathname: '/reports/product-sell'
              });
            }
          }
        },
        {
          key: '/reports/product-purchase',
          label: (
            <Tooltip title="Product Purchase Report" color="blue">
              <Link to="/reports/product-purchase">Product Purchase Report</Link>
            </Tooltip>
          ),
          onClick: (val: IClickValueTypes) => {
            if (!val.domEvent.ctrlKey) {
              history.push({
                pathname: '/reports/product-purchase'
              });
            }
          }
        },
        {
          key: '/reports/full-day-report',
          label: (
            <Tooltip title="Full Day Report" color="blue">
              {' '}
              <Link to="/reports/full-day-report">Full Day Report</Link>
            </Tooltip>
          ),
          onClick: (val: IClickValueTypes) => {
            if (!val.domEvent.ctrlKey) {
              history.push({
                pathname: '/reports/full-day-report'
              });
            }
          }
        },
        {
          key: '/reports/sales-representative-report',
          label: (
            <Tooltip title="Sales Representative Report" color="blue">
              {' '}
              <Link to="/reports/sales-representative-report">Sell Representative Report</Link>
            </Tooltip>
          ),
          onClick: (val: IClickValueTypes) => {
            if (!val.domEvent.ctrlKey) {
              history.push({
                pathname: '/reports/sales-representative-report'
              });
            }
          }
        },
        {
          key: '/reports/customer-supplier-report',
          label: (
            <Tooltip title="Customer & Supplier Report" color="blue">
              {' '}
              <Link to="/reports/customer-supplier-report">Customer & Supplier Report</Link>
            </Tooltip>
          ),
          onClick: (val: IClickValueTypes) => {
            if (!val.domEvent.ctrlKey) {
              history.push({
                pathname: '/reports/customer-supplier-report'
              });
            }
          }
        },
        {
          key: '/reports/customer-net-sales',
          label: (
            <Tooltip title="Customer Net Sales Report" color="blue">
              {' '}
              <Link to="/reports/customer-net-sales">Customer Net Sales Report</Link>
            </Tooltip>
          ),
          onClick: (val: IClickValueTypes) => {
            if (!val.domEvent.ctrlKey) {
              history.push({
                pathname: '/reports/customer-net-sales'
              });
            }
          }
        },
        {
          key: '/reports/adjustment',
          label: (
            <Tooltip title="Product Adjustment Report" color="blue">
              {' '}
              <Link to="/reports/adjustment">Product Adjustment Report</Link>
            </Tooltip>
          ),
          onClick: (val: IClickValueTypes) => {
            if (!val.domEvent.ctrlKey) {
              history.push({
                pathname: '/reports/adjustment'
              });
            }
          }
        },
        {
          key: '/reports/pending-payment-sell',
          label: (
            <Tooltip title="Pending Payment Sell Report" color="blue">
              <Link to="/reports/pending-payment-sell">Pending Payment Sell Report</Link>
            </Tooltip>
          ),
          onClick: (val: IClickValueTypes) => {
            if (!val.domEvent.ctrlKey) {
              history.push({
                pathname: '/reports/pending-payment-sell'
              });
            }
          }
        },
        {
          key: '/reports/transfers',
          label: (
            <Tooltip title="Transfers Report" color="blue">
              <Link to="/reports/transfers">Stock Transfer Report</Link>
            </Tooltip>
          ),
          onClick: (val: IClickValueTypes) => {
            if (!val.domEvent.ctrlKey) {
              history.push({
                pathname: '/reports/transfers'
              });
            }
          }
        },
        {
          key: '/reports/opening-stock',
          label: (
            <Tooltip title="Opening Stock Report" color="blue">
              {' '}
              <Link to="/reports/opening-stock">Opening Stock Report</Link>
            </Tooltip>
          ),
          onClick: (val: IClickValueTypes) => {
            if (!val.domEvent.ctrlKey) {
              history.push({
                pathname: '/reports/opening-stock'
              });
            }
          }
        },
        {
          key: '/reports/stock-history',
          // icon: <img src={ReportIcon} className="menu-icons" />,
          label: (
            <Tooltip title="Transfers Report" color="blue">
              <Link to="/reports/stock-history">Stock History Report</Link>
            </Tooltip>
          ),
          onClick: (val: IClickValueTypes) => {
            if (!val.domEvent.ctrlKey) {
              history.push({
                pathname: '/reports/stock-history'
              });
            }
          }
        },
        {
          key: '/reports/profit-calculation',
          label: (
            <Tooltip title="Profit Calculation Report" color="blue">
              {' '}
              <Link to="/reports/profit-calculation">Profit Calculation Report</Link>
            </Tooltip>
          ),
          onClick: (val: IClickValueTypes) => {
            if (!val.domEvent.ctrlKey) {
              history.push({
                pathname: '/reports/profit-calculation'
              });
            }
          }
        },
        {
          key: '/reports/top-purchase-product',
          label: (
            <Tooltip title="Top Purchase Product By Quantity Report" color="blue">
              {' '}
              <Link to="/reports/profit-calculation">Top Purchase Product Report</Link>
            </Tooltip>
          ),
          onClick: (val: IClickValueTypes) => {
            if (!val.domEvent.ctrlKey) {
              history.push({
                pathname: '/reports/top-purchase-product'
              });
            }
          }
        },
        {
          key: '/reports/top-sale-product',
          label: (
            <Tooltip title="Top Sale Product By Quantity Report" color="blue">
              {' '}
              <Link to="/reports/profit-calculation">Top Sale Product Report</Link>
            </Tooltip>
          ),
          onClick: (val: IClickValueTypes) => {
            if (!val.domEvent.ctrlKey) {
              history.push({
                pathname: '/reports/top-sale-product'
              });
            }
          }
        },
        {
          key: '/reports/wastage-by-product',
          label: (
            <Tooltip title="Wastage By Products Quantity Report" color="blue">
              {' '}
              <Link to="/reports/profit-calculation">Wastage By Products Report</Link>
            </Tooltip>
          ),
          onClick: (val: IClickValueTypes) => {
            if (!val.domEvent.ctrlKey) {
              history.push({
                pathname: '/reports/wastage-by-product'
              });
            }
          }
        },
        {
          key: '/reports/custom-customer',
          label: (
            <Tooltip title="Custom Customer Report" color="blue">
              {' '}
              <Link to="/reports/custom-customer">Custom Customer Report</Link>
            </Tooltip>
          ),
          onClick: (val: IClickValueTypes) => {
            if (!val.domEvent.ctrlKey) {
              history.push({
                pathname: '/reports/custom-customer'
              });
            }
          }
        },
        {
          key: '/reports/route-history',
          label: (
            <Tooltip title="Route History Report" color="blue">
              {' '}
              <Link to="/reports/route-history">Route History Report</Link>
            </Tooltip>
          ),
          onClick: (val: IClickValueTypes) => {
            if (!val.domEvent.ctrlKey) {
              history.push({
                pathname: '/reports/route-history'
              });
            }
          }
        },
        {
          key: '/reports/all-product-quantity',
          label: (
            <Tooltip title="All Product Quantity Report" color="blue">
              {' '}
              <Link to="/reports/all-product-quantity">All Product Quantity Report</Link>
            </Tooltip>
          ),
          onClick: (val: IClickValueTypes) => {
            if (!val.domEvent.ctrlKey) {
              history.push({
                pathname: '/reports/all-product-quantity'
              });
            }
          }
        },
        {
          key: '/reports/all-product-sell-return-report',
          label: (
            <Tooltip title="All Product Sell Return Report" color="blue">
              {' '}
              <Link to="/reports/all-product-sell-return-report">
                All Product Sell Return Report
              </Link>
            </Tooltip>
          ),
          onClick: (val: IClickValueTypes) => {
            if (!val.domEvent.ctrlKey) {
              history.push({
                pathname: '/reports/all-product-sell-return-report'
              });
            }
          }
        },
        {
          key: '/reports/purchase-sell-summation',
          label: (
            <Tooltip title="Purchase/Sell Payment Summation Report" color="blue">
              {' '}
              <Link to="/reports/purchase-sell-summation">
                Purchase/Sell Payment Summation Report
              </Link>
            </Tooltip>
          ),
          onClick: (val: IClickValueTypes) => {
            if (!val.domEvent.ctrlKey) {
              history.push({
                pathname: '/reports/purchase-sell-summation'
              });
            }
          }
        },
        {
          key: '/reports/top-sell-products-from-customer-report',
          label: (
            <Tooltip title="Top Sell Product From Customer Report" color="blue">
              {' '}
              <Link to="/reports/top-sell-products-from-customer-report">
                Top Sell Product From Customer Report
              </Link>
            </Tooltip>
          ),
          onClick: (val: IClickValueTypes) => {
            if (!val.domEvent.ctrlKey) {
              history.push({
                pathname: '/reports/top-sell-products-from-customer'
              });
            }
          }
        },

        ...childrenForReports
      ]
    },
    estimate: {
      key: '18',
      label: 'Estimation',
      icon: <img src={EstimationIcon} className="menu-icons" />,
      children: [
        {
          key: '/estimate/procurement',
          label: 'Procurement',
          onClick: (val: IClickValueTypes) => {
            if (!val.domEvent.ctrlKey) {
              history.push({
                pathname: '/estimate/procurement'
              });
            }
          }
        },
        {
          key: '/estimate/sell',
          label: 'Sell',
          onClick: (val: IClickValueTypes) => {
            if (!val.domEvent.ctrlKey) {
              history.push({
                pathname: '/estimate/sell'
              });
            }
          }
        }
      ]
    },
    tracking: {
      key: '19',
      icon: <CarOutlined />,
      label: 'Tracking',
      children: childrenfortracking
    },

    cropcalendar: {
      key: '20',
      icon: <CalendarOutlined />,
      label: 'Crop Calendar',
      children: [
        {
          key: '/cropcalendar',
          label: <Link to="/crop-calendar">View</Link>,
          onClick: (val: IClickValueTypes) => {
            if (!val.domEvent.ctrlKey) {
              history.push({
                pathname: '/crop-calendar'
              });
            }
          }
        }
      ]
    },
    wallet: {
      key: '20',
      icon: <MoneyCollectOutlined />,
      label: 'Wallet',
      children: [
        {
          key: '/wallet-payments',
          label: <Link to="/wallet-payments">List</Link>,
          onClick: (val: IClickValueTypes) => {
            if (!val.domEvent.ctrlKey) {
              history.push({
                pathname: '/wallet-payments'
              });
            }
          }
        }
      ]
    },
    // expense: {
    //   key: '21',
    //   icon: <MoneyCollectOutlined />,
    //   label: 'Expense',
    //   children: childrenforExpense
    // },

    logs: {
      key: '22',
      icon: <FileTextOutlined />,
      label: 'Logs',
      children: childrenforSystemLogs
    },

    accounts: {
      key: '23',
      icon: <TransactionOutlined />,
      label: 'Accounts',
      children: childrenForAccounts
    },

    epay: {
      key: '24',
      icon: <PayCircleOutlined />,
      label: 'Epay',
      children: childrenForEpay
    },
    ird: {
      key: '25',
      icon: <BankOutlined />,
      label: 'IRD',
      children: childrenforIrd
    },
    bank: {
      key: '26',
      icon: <BankOutlined />,
      label: 'Bank',
      children: childrenForBank
    },
    session: {
      key: '27',
      icon: <FieldTimeOutlined />,
      label: 'Session',
      children: childrenForSession
    }
  } as const;
  type keys = keyof typeof mapper;

  return newmenuorder.map((currmenu: keys) => mapper[currmenu]);
};

interface menuitem {
  key: string;
  icon: JsxElement;
  label: string;
  onClick: () => void;
  children?: menuitem[];
}

// export const menu =  newmenuorder.map((currmenu: keys) => mapper[currmenu]);
export const rootSubmenuKeys = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  // '21',
  '22',
  '23',
  '24',
  '25',
  '26'
];
